import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import UserContextProvider, { UserContext } from "./Contexts/UserContext";
import GlobalContextProvider, { GlobalContext } from "./Contexts/GlobalContext";

import { useContext, useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { withSnackbar, useSnackbar } from "notistack";
import { Button, Dialog } from "@mui/material";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import AgentFlow from "./Routes/AgentFlow";
import { environment } from "./config";
import ErrorFallback from "./pages/ErrorFallback";
import NoConnection from "./components/NoConnection";
import { MixPanelTransport } from "./util/MixPanelTransport";
import MixPanelAudit from "./util/MixPanelAudit";
import { getTokenFn, onMessageListener } from "./config/firebaseInit";
import NotificationPopup from "./components/V3/NotificationPopup";

function App() {
  const [newVersionAvailable, setNewVersion] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOnline, setOnline] = useState(true);

  const { tokenState, notificationState, notiCountState } =
    useContext(GlobalContext);
  const [tokenData, setTokenData] = tokenState;
  const [notificationData, setNotification] = notificationState;
  const [notiCount, setNotiCount] = notiCountState;

  const onServiceWorkerUpdate = (registration) => {
    setNewVersion(true);
    setWaitingWorker(registration?.waiting);
  };

  const updateServiceWorker = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setNewVersion(false);
    window.location.reload();
  };

  useEffect(() => {
    // if (environment === "development") {
    //   localStorage.setItem("nbfc", "NY00002");
    // } else {
    //   if (window.location.host.includes(`prestloans`)) {
    //     localStorage.setItem("nbfc", "PL00003");
    //   } else if (window.location.host.includes(`vani`)) {
    //     localStorage.setItem("nbfc", "VA00001");
    //   } else if (window.location.host.includes(`nyleasing`)) {
    //     localStorage.setItem("nbfc", "NY00002");
    //   }
    // }
    const transport = new MixPanelTransport("4acc134bfe07d27c9a65ea894445bdc4");
    MixPanelAudit.init([transport]);

    if (window.location.host.includes(`nyleasing`)) {
      window.location.replace("https://erick.embifi.in");
    }
    MixPanelAudit.intercept("erick_open", {});
    getTokenFn(setTokenData);
  }, []);

  onMessageListener()
    .then((payload) => {
      setNotification({
        open: true,
        title: payload?.notification?.title || "",
        body: payload?.notification?.body || "",
        image: payload?.notification?.image || "",
      });
      setNotiCount(notiCount + 1);
      
    })
    .catch((err) => console.log("failed: ", err));

  const refreshAction = (key) => {
    return (
      <>
        <Button
          className="snackbar-button"
          size="small"
          onClick={updateServiceWorker}
        >
          {"refresh"}
        </Button>
      </>
    );
  };

  // useEffect(() => {
  //   serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
  // }, []);

  useEffect(() => {
    if (newVersionAvailable) {
      enqueueSnackbar("Updating to new version", {
        variant: "success",
      });
      updateServiceWorker();
    }
    // enqueueSnackbar("A new version is available", {
    //   persist: true,
    //   variant: "success",
    //   action: refreshAction(),
    // });
  }, [newVersionAvailable]);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, errorInfo) => console.log({ error, errorInfo })}
        onReset={() => {
          // reset the state of your app
        }}
      >
        <div className="app-wrapper">
          {/* <OldFlow/> */}
          <Router>
            <AgentFlow />
          </Router>
        </div>
      </ErrorBoundary>
      {!isOnline && <NoConnection />}
    </>
  );
}

export default App;
