import {
  BottomNavigation,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { forwardRef } from "react";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import MenuAppBar from "../../../components/Appbar";
import MultipleUpload from "../../../components/MultipleUpload";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import { useSnackbar } from "notistack";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  customerFieldInvestigation,
  fiSendOtp,
  updateFieldInvestigation,
} from "../../../api/agent";
import SuccessCard from "../../../components/SuccessCard";
import useApplication from "../../../hooks/useApplication";
import CustomInputAutocomplete from "../../../components/AutoComplete";
import CatureUpload from "../../../components/CaptureV3";
import { isBlank } from "../../../validations/fieldValidations";
import LoadingOver from "../../../components/LoadingOver";
import { l } from "../../../util/languageConvertor";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const FieldInvestigationDone = forwardRef((props, ref) => {
  const { bottomNav, otpState, langState } = useContext(GlobalContext);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { enqueueSnackbar } = useSnackbar();
  const { application_id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [overLoading, setOverLoading] = useState(false);
  const [isAccepted, setAccepted] = useState(false);
  const [fieldDocs, setFieldDocs] = useState([]);

  const [lang, setLang] = langState;

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  return (
    <>
      <Box className="w-100 p-0" sx={{ pb: 3 }} ref={ref}>
        <MenuAppBar
          home={false}
          action={() => {
            navigate(`/draft-applications`);
          }}
          shadow={true}
        />
        <CssBaseline />
        <CssBaseline />
        <div className="main-wrapper p-0">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Borrower", lang)}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Customer Name", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">{journeyData?.full_name}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Address", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.current_address}
              </label>
            </Grid>

            <Grid item xs={6} sm={3} className="py-0">
              <label className="form-label-black">{l("Premises Ownership", lang)} :</label>
            </Grid>
            <Grid item xs={6} sm={7} className="py-0">
              <label className="form-label">
                {journeyData?.is_property_owned ? "Yes" : "No"}
              </label>
            </Grid>

            <Grid item xs={6} sm={3} className="py-0">
              <label className="form-label-black">
              {l("Total Number Of family members", lang)} :
              </label>
            </Grid>
            <Grid item xs={6} sm={7} className="py-0">
              <label className="form-label">{journeyData?.total_members}</label>
            </Grid>
            <Grid item xs={6} sm={3} className="py-0">
              <label className="form-label-black">
              {l("Number of earning members in family", lang)} :
              </label>
            </Grid>
            <Grid item xs={6} sm={7} className="py-0">
              <label className="form-label">
                {journeyData?.earning_members}
              </label>
            </Grid>
            <Grid item xs={6} sm={3} className="py-0">
              <label className="form-label-black">
              {l("Annual family income (In Rs)", lang)}:
              </label>
            </Grid>
            <Grid item xs={6} sm={7} className="py-0">
              <label className="form-label">{journeyData?.family_income}</label>
            </Grid>

            {/* ////////// Dealer ////////// */}

            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Dealer", lang)}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Dealer Name", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">{journeyData?.anchor_name}</label>
            </Grid>

            {/* ////////// Vehicle details ////////// */}

            {/* <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Vehicle Details", lang)}</label>
            </Grid>

            <Grid item xs={4} sm={3} className="py-0">
              <label className="form-label-black">{l("Vehicle Engine No.", lang)}:</label>
            </Grid>
            <Grid item xs={8} sm={9} className="py-0">
              <label className="form-label">{journeyData?.engine_number}</label>
            </Grid>

            <Grid item xs={4} sm={3} className="py-0">
              <label className="form-label-black">{l("Vehicle Chasis no", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={9} className="py-0">
              <label className="form-label">{journeyData?.chasis_no}</label>
            </Grid> */}

            {/* ////////// Photos ////////// */}
            {/* 
            <Grid item xs={12} sm={12}>
              <label className="form-head">Photos</label>
            </Grid> */}

            {/* ////// References ///////////// */}

            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Reference 1", lang)}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Name", lang)}:</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.reference1_name}
              </label>
            </Grid>
            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Mobile Number", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.reference1_mob}
              </label>
            </Grid>
            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Relation with borrower", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.reference1_relation}
              </label>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Reference 2", lang)} :</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Name", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.reference2_name}
              </label>
            </Grid>
            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Mobile Number", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.reference2_mob}
              </label>
            </Grid>
            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Relation with borrower", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.reference2_relation}
              </label>
            </Grid>

            <Grid item xs={4} sm={3} className="py-0">
              <label className="form-label-black">
              {l("Accessbility till premise", lang)} :
              </label>
            </Grid>
            <Grid item xs={8} sm={9} className="py-0">
              <label className="form-label">
                {journeyData?.premise_accessibility}
              </label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Final Remark on the file", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">{journeyData?.fi_remark}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Name", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.investigator_name}
              </label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Mobile Number", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.investigator_mobile}
              </label>
            </Grid>
          </Grid>
        </div>

        {/* <Paper className="paper-bottom" elevation={3} ref={bottomNav}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                // className={`btn-submit ${
                //   !showFinalCalculation && "btn-submit-disabled"
                // }`}
                className={`btn-submit`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper> */}
      </Box>
      {overLoading && <LoadingOver text={"Updating"} />}
    </>
  );
});

export default FieldInvestigationDone;
