import { Grid } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GridViewIcon from "@mui/icons-material/GridView";
import uploadedIco from "../../assets/uploaded.svg";
import rejectedIco from "../../assets/rejectedIco.svg";
import approvedIco from "../../assets/approved.svg";
import SelectUpload from "./SelectUpload";
import RejectionPopup from "../V3/RejectionPopup1";

const ContinueUpload = ({
  name,
  icon,
  isRequired,
  onClick,
  started,
  completed,
  status,
  pages,
  isMulti,
  setState,
  documents,
  handleUpload,
  isLoading,
  extraHeight,
}) => {
  return !isMulti ? (
    <>
      <SelectUpload
        openMult={() => {
          onClick();
        }}
        text={name}
        isRequired={isRequired}
        icon={icon}
        tempState={documents?.slice(-1)[0]?.fileLink || ""}
        isUpload={documents?.length > 0}
        status={documents?.slice(-1)[0]?.isApproved}
        setFile={(file) => {
          handleUpload(file);
        }}
        isLoading={isLoading}
        extraHeight={extraHeight}
      />
      {documents?.slice(-1)[0]?.isApproved === false &&
        documents?.slice(-1)[0]?.rejectionReason?.length > 0 && (
          <RejectionPopup message={documents?.slice(-1)[0]?.rejectionReason} />
        )}
    </>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {/* <div
          className="doc-uploaded-success"
          onClick={() => {
            onClick();
          }}
        >
          <p className="mb-0">
            <GridViewIcon className="combine-icon" />
          </p>

          <p
            className="mb-0"
            style={{ color: "gray", fontSize: "12px", marginLeft: "35px" }}
          >
            Continue upload
          </p>
        </div> */}

        <div
          className="documents-card-v3 pointer"
          style={{
            height: extraHeight,
          }}
          onClick={() => {
            if (!status) {
              onClick();
            }
          }}
        >
          {completed && (
            <div className="doc-status-holder">
              <img
                src={(() => {
                  switch (status) {
                    case true:
                      return approvedIco;
                    case false:
                      return rejectedIco;
                    default:
                      return uploadedIco;
                  }
                })()}
                alt=""
                width={40}
                height={40}
              />
            </div>
          )}
          {!completed && started && (
            <div className="doc-status-holder">
              <span className="page-count-badge">{pages}</span>
            </div>
          )}
          <div>
            <p className="mb-2 text-center">
              <img
                src={icon}
                alt=""
                width={60}
                height={60}
                style={{ objectFit: "contain" }}
              />
            </p>
            <p className="heading mb-0">
              {name} {isRequired && <span className="mand-star">*</span>}
            </p>
          </div>
        </div>

        {status === false &&
          documents?.[0]?.rejectionReason?.length > 0 && (
            <RejectionPopup
              message={documents?.[0]?.rejectionReason}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default ContinueUpload;
