import { Box, Grid } from "@mui/material";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CustomInputAutocomplete from "../../components/AutoComplete";
import Select from "../../components/Select";
import { kycContext } from "../../Contexts/KycContext";
import { BanksList } from "../../util/constants";

import {
  isAccValid,
  isBlank,
  isIFSCValid,
} from "../../validations/fieldValidations";
import { l } from "../../util/languageConvertor";
import { GlobalContext } from "../../Contexts/GlobalContext";

const BankDetails = forwardRef((props, ref) => {
  const { accountDetailsState } = useContext(kycContext);
  const { langstate } = useContext(GlobalContext);
  const [lang,setLang]=langstate;

  const [banks, setBanks] = useState([]);
  const [ifsc, setIfsc] = useState("");
  const [accountDetails, setAccountDetails] = accountDetailsState;
  const [fieldErrors, setFieldErrors] = useState({
    acntName: "",
    acntNum: "",
    conAcntNum: "",
    bank: "",
    ifsc: "",
  });

  const isValid = () => {
    let valid = true;
    console.log(accountDetails);
    let errors = {
      acntName: isBlank(accountDetails.acntName),
      acntNum: isAccValid(accountDetails.acntNum),
      conAcntNum: isAccValid(accountDetails.conAcntNum),
      bank: accountDetails.bank === "" ? "Select a bank" : "",
      type: accountDetails.type === "" ? "Select account type" : "",
      ifsc: isIFSCValid(accountDetails.ifsc),
    };

    // console.log(isAccValid(accountDetails));

    setFieldErrors(errors);

    for (let i in errors) {
      if (errors[i].length > 0) {
        valid = false;
      }
    }

    return valid;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      if (isValid()) {
        return true;
      }
    },
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    getBankList();
  }, []);

  const getBankList = () => {
    BanksList.forEach((val) => {
      banks.push(val.bank);
    });
    setBanks(banks);
  };

  return (
    <>
      <Grid container spacing={2} className="pb-4">
        <Grid item xs={12} sm={12}>
          <label className="form-label">
            Enter Beneficiary Account Name <span className="mand-star">*</span>
          </label>
          <input
            className="form-input w-100"
            autoComplete="given-name"
            name="accountName"
            required
            fullWidth
            placeholder="Enter your Account Name"
            value={accountDetails.acntName}
            onChange={(e) => {
              setAccountDetails((prev) => ({
                ...prev,
                acntName: e.target.value,
              }));
            }}
            onKeyUp={(e) =>
              setFieldErrors((st) => ({
                ...st,
                acntName: isBlank(e.target.value),
              }))
            }
          />
          <span className="error">{fieldErrors.acntName}</span>
        </Grid>

        <Grid item xs={12} sm={12}>
          <label className="form-label">
            Select Bank <span className="mand-star">*</span>
          </label>

          <CustomInputAutocomplete
            options={banks}
            onChange={(val) => {
              setFieldErrors((prev) => ({ ...prev, bank: "" }));
              setAccountDetails((prev) => ({
                ...prev,
                bank: val
              }));
              // setIfsc(bank?.ifsc);
            }}
          />

          <span className="error">{fieldErrors.bank}</span>
        </Grid>

        <Grid item xs={12} sm={12}>
          <label className="form-label">
            Bank Account type <span className="mand-star">*</span>
          </label>
          <Select
            options={["SAVINGS", "CURRENT"]}
            text="Select account type"
            getData={(val) => {
              setFieldErrors((prev) => ({ ...prev, type: "" }));
              setAccountDetails((prev) => ({ ...prev, type: val }));
            }}
          />
          <span className="error">{fieldErrors.type}</span>
        </Grid>

        <Grid item xs={12} sm={12}>
          <label className="form-label">
            Enter Account Number <span className="mand-star">*</span>
          </label>
          <input
            className="form-input w-100"
            autoComplete="given-name"
            name="accountNumber"
            type={"text"}
            required
            fullWidth
            placeholder="Enter your Account Number"
            value={accountDetails.acntNum}
            onChange={(e) => {
              if (
                e.target.value !== "" &&
                !/^\d+$/.test(e.target.value)
              ) {
                return false;
              }
              setAccountDetails((prev) => ({
                ...prev,
                acntNum: e.target.value,
              }));
            }}
            onKeyUp={(e) =>
              setFieldErrors((st) => ({
                ...st,
                acntNum: isAccValid(e.target.value),
              }))
            }
            onBlur={(e) => {
              if (
                accountDetails.conAcntNum !== "" &&
                e.target.value !== accountDetails.conAcntNum
              ) {
                setFieldErrors((st) => ({
                  ...st,
                  conAcntNum: "Should match with account number",
                }));
              }
            }}
            onCopy={(e) => {
              e.preventDefault();
            }}
            onPaste={(e) => {
              e.preventDefault();
            }}
          />
          <span className="error">{fieldErrors.acntNum}</span>
        </Grid>

        <Grid item xs={12} sm={12}>
          <label className="form-label">
            Confirm Account Number <span className="mand-star">*</span>
          </label>
          <input
            className="form-input w-100"
            autoComplete="given-name"
            name="confirmAccount"
            type={"text"}
            placeholder="Confirm your Account Number"
            value={accountDetails.conAcntNum}
            onChange={(e) => {
              if (
                e.target.value !== "" &&
                !/^\d+$/.test(e.target.value)
              ) {
                return false;
              }
              setAccountDetails((prev) => ({
                ...prev,
                conAcntNum: e.target.value,
              }));
            }}
            onKeyUp={(e) =>
              setFieldErrors((st) => ({
                ...st,
                conAcntNum: isAccValid(e.target.value),
              }))
            }
            onBlur={(e) => {
              if (e.target.value !== accountDetails.acntNum) {
                setFieldErrors((st) => ({
                  ...st,
                  conAcntNum: "Should match with account number",
                }));
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
            }}
          />
          <span className="error">{fieldErrors.conAcntNum}</span>
        </Grid>

        <Grid item xs={12} sm={12}>
          <label className="form-label">
            Enter IFSC Code <span className="mand-star">*</span>
          </label>
          <input
            className="form-input w-100"
            autoComplete="given-name"
            name="ifsc"
            required
            maxlength="11"
            fullWidth
            placeholder="Enter IFSC Code"
            value={accountDetails.ifsc}
            onChange={(e) => {
              let value = e.target.value;
              // if (/^[A-Za-z]+$/.test(value.slice(4, value.length))) return;
              setAccountDetails((prev) => ({
                ...prev,
                ifsc: value.toUpperCase()
              }));
            }}
            onKeyUp={(e) =>
              setFieldErrors((st) => ({
                ...st,
                ifsc: isIFSCValid(e.target.value),
              }))
            }
          />
          <span className="error">{fieldErrors.ifsc}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <p style={{ color: "#4D61FC", fontSize: "14px" }} className="pe-2">
            {l("Note: Submit Bank Account Detail for E-Rick Loan.",lang)}
          </p>
        </Grid>
      </Grid>
    </>
  );
});

export default BankDetails;
