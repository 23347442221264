import { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Documents from "./Documents";
import { Button, CircularProgress } from "@mui/material";
import MenuAppBar from "../../components/Appbar";
import BankDetails from "./BankDetails";
import { kycContext } from "../../Contexts/KycContext";
import GstPage from "./GstPage";
import { UserContext } from "../../Contexts/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import AadharVerifications from "./AadhaarVerification";
import { updateBankDetails, updateGstData, uploadDocuments } from "../../api";
import { useSnackbar } from "notistack";
import KycPop from "../../components/KycPop";
import PennyDrop from "../../components/PennyDrop";
import PermissionModal from "../../components/PermissionModal";
import usePermission from "../../hooks/usePermission";
import { GlobalContext } from "../../Contexts/GlobalContext";

export default function KycLayout() {
  const { bottomNav } = useContext(GlobalContext);
  const [value, setValue] = useState(0);
  const [activeStep, setActive] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const ref = useRef(null);
  const documentRef = useRef(null);
  const BankDetailsRef = useRef(null);
  const GstPageRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const changeStep = (val) => {
    setActive((st) => st + val);
  };

  const {
    kycDataState,
    aadharVerifState,
    gstDataState,
    pennyState,
    accountDetailsState,
  } = useContext(kycContext);
  const { user, setUser } = useContext(UserContext);
  const { showPermissionState } = useContext(GlobalContext);

  const [kycData, setKycData] = kycDataState;
  const [gstData, setGstData] = gstDataState;
  const [pennyDrop, setPennyDrop] = pennyState;
  const [isAadharVerified, setAadharVerfiy] = aadharVerifState;
  const [accountDetails, setAccountDetails] = accountDetailsState;
  const [showPermission] = showPermissionState;

  const { stage } = useParams();

  const { permissions, getUserLocation } = usePermission();

  const handleDocument = async () => {
    if (kycData.isAadharNum && !isAadharVerified) {
      if (documentRef.current.gotoAdhaarVerification()) {
        setActive(4);
      }
    } else {
      try {
        if (documentRef.current.handleSubmit()) {
          let payload = {
            business_name: kycData.businessName,
            business_address: kycData.businessAddress,
            selfie: kycData.selfie,
            business_site_photo: kycData.sitePhoto,
            isAadharNumber: kycData.isAadharNum,
            aadhar_back: kycData.isAadharNum ? "" : kycData.aadhar_back,
            aadhar_front: kycData.isAadharNum ? "" : kycData.aadhar_front,
            isPanNumber: kycData.isPanNum,
            pan_image: kycData.isPanNum ? "" : kycData.panFront,
            pan_number: kycData.panNumber,
          };

          setLoading(true);

          let { data } = await uploadDocuments(payload);

          setLoading(false);

          console.log(data);
          if (data?.status) {
            setUser((st) => ({ ...st, kycStage: "completed" }));
            changeStep(1);
          }
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error?.response?.data?.errors[0].message, {
          variant: "error",
        });
      }
    }

    // documentRef.current.handleSubmit();
    // changeStep(1);
  };

  const handleGstPage = async () => {
    if (GstPageRef.current.handleSubmit()) {
      setLoading(true);
      let payload = {
        gstin_number: gstData.gstin,
        trade_license: gstData.tradeLicense,
      };

      try {
        let { data } = await updateGstData(payload);
        if (data?.status) {
          setUser((st) => ({ ...st, gstStage: data?.gstStage }));
          if (stage) {
            navigate("/home/profile-view");
          } else {
            setActive(3);
          }
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.errors[0].message, {
          variant: "error",
        });
      }

      setLoading(false);
    }
  };

  const handleBankDetails = async () => {
    if (BankDetailsRef.current.handleSubmit()) {
      setLoading(true);
      setPennyDrop((st) => ({ ...st, loading: true }));
      let payload = {
        account_holder_name: accountDetails.acntName,
        bank: accountDetails.bank,
        account_type: accountDetails.type,
        account_number: accountDetails.acntNum,
        ifsc_code: accountDetails.ifsc,
      };

      try {
        let { data } = await updateBankDetails(payload);
        if (data?.status) {
          setPennyDrop((st) => ({ ...st, status: true }));
          setTimeout(() => {
            setUser((st) => ({ ...st, kycStage: "completed" }));
            if (stage) {
              navigate("/home/profile-view");
            } else {
              navigate("/home");
            }
          }, 2000);
        }
      } catch (error) {
        setPennyDrop((st) => ({ ...st, loading: false }));
        enqueueSnackbar(error?.response?.data?.errors[0]?.message, {
          variant: "error",
        });
      }
      setLoading(false);
    }
  };

  const handleProceed = () => {
    if (activeStep === 1) handleDocument();
    if (activeStep === 2) handleGstPage();
    if (activeStep === 3) handleBankDetails();
  };

  const handleSkip = () => {
    if (stage) {
      navigate("/home/profile-view");
    } else {
      if (activeStep === 2) changeStep(1);
      if (activeStep === 3) navigate("/home");
    }
  };

  const handleBack = () => {
    if (stage) {
      navigate("/home/profile-view");
    } else {
      if (
        activeStep === 3 &&
        (!user?.gstStage?.gst || !user?.gstStage?.trade_license)
      ) {
        setActive(2);
        return;
      }
      navigate("/home");
    }
  };

  useEffect(() => {
    // findActiveStage(user?.kycStage);

    if (stage) {
      switch (stage) {
        case "gst":
          setActive(2);
          break;
        case "bank-details":
          setActive(3);
          break;
        default:
          navigate("/home/profile-view");
      }
    } else {
      if (user?.kycStage === "completed") {
        if (user?.gstStage?.gst && user?.gstStage?.trade_license) setActive(3);
        else setActive(2);
      }
    }
  }, [user]);

  // const findActiveStage = (stage) => {
  //   switch (stage) {
  //     case "none":
  //       setActive(1);
  //       break;
  //     case "gst":
  //       setActive(2);
  //       break;
  //     case "bank-details":
  //       setActive(3);
  //       break;
  //     case "completed":
  //       navigate("/home");
  //       break;
  //   }
  // };

  return activeStep !== 4 ? (
    <div className="content-center">
      {pennyDrop.loading && <PennyDrop />}

      <Box
        className="kyc-wrapper w-100"
        sx={{ pb: activeStep !== 1 ? 10 : 7, pt: 7 }}
        ref={ref}
      >
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper">
          {activeStep === 1 && <Documents ref={documentRef} />}
          {activeStep === 2 && <GstPage ref={GstPageRef} />}
          {activeStep === 3 && <BankDetails ref={BankDetailsRef} />}
        </div>

        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, boxShadow: 0 }}
          elevation={3}
          ref={bottomNav}
        >
          <BottomNavigation
            className="content-center"
            style={{ height: "auto" }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                disabled={isLoading}
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                onClick={() => handleProceed()}
              >
                {!isLoading ? (
                  (() => {
                    if (
                      activeStep === 1 &&
                      kycData.isAadharNum &&
                      !isAadharVerified
                    ) {
                      return "Verify Aadhar";
                    } else {
                      return "Submit";
                    }
                  })()
                ) : (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                )}
              </Button>

              {activeStep !== 1 && (
                <Button
                  className="btn-skip"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => handleSkip()}
                >
                  {(() => {
                    if (activeStep === 2) {
                      return "Skip";
                    } else {
                      return "Submit Later";
                    }
                  })()}
                </Button>
              )}
            </div>
          </BottomNavigation>
        </Paper>
      </Box>

      {(permissions.camera === "PROMPT" || permissions.location === "PROMPT") &&
        showPermission.state && <PermissionModal initial={true} />}
    </div>
  ) : (
    <AadharVerifications setActive={setActive} />
  );
}
