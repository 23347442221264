import { Button, CssBaseline, Grid } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import DocumentCard from "../../../../components/V3/DocumentCard";
import UploadCard from "../../../../components/V3/UploadCard";
import customerIco from "../../../../assets/tabs/customerCard.svg";
import licenseIco from "../../../../assets/tabs/drivingLicense.svg";
import electricityIco from "../../../../assets/tabs/electricityIco.svg";
import otherIco from "../../../../assets/tabs/otherProofIco.svg";
import voterIdFront from "../../../../assets/tabs/voterFrontIco.svg";
import voterIdBack from "../../../../assets/tabs/voterBackIco.svg";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { uploadDocument } from "../../../../api/agent";
import MenuAppBar from "../../../../components/Appbar";
import { useNavigate } from "react-router-dom";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { l } from "../../../../util/languageConvertor";
import addmoreIco from "../../../../assets/tabs/addmoreIco.svg";

const CoCustomers = ({ application_id, openStatus }) => {
  const { user } = useContext(UserContext);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { getApplicationDetails } = useApplication();
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  const { langState, docPopupState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [docPopup, setDocPopup] = docPopupState;
  const [co_customer_other_documents, setOtherDocs] = useState([]);

  const handleUpload = async (file, name, overRide) => {
    setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        `?type=co-customer&override=${overRide}`
      );
      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }
      getApplicationDetails(application_id, true);
    } catch (error) {}
    setLoading("");
  };

  // useEffect(() => {
  //   console.log(journeyData?.customer_documents);
  // }, [journeyData]);

  useEffect(() => {
    let doc = journeyData?.co_customer_documents?.co_customer_other_documents;
    if (doc?.length > 0) {
      setOtherDocs([...doc, { name: `co_customer_other_documents_${doc?.length}` }]);
    } else {
      setOtherDocs([{ name: "co_customer_other_documents_1" }]);
    }
  }, [journeyData]);

  return (
    <div>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
        leftContent={
          <Button
            color="secondary"
            onClick={() => {
              MixPanelAudit.intercept("check_status_click", {
                distinct_id: user?.mobile_number[0],
                agent_name: user?.name,
                application_id,
              });
              openStatus();
            }}
          >
            {l("Check Status", lang)}
          </Button>
        }
      />
      <CssBaseline />
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={6} sm={6}>
          <DocumentCard
            text={
              <span style={{ paddingLeft: "5px" }}>
                {l("Co-Borrower Photo", lang)}
              </span>
            }
            isRequired={true}
            icon={customerIco}
            tempState={
              journeyData?.co_customer_documents?.co_customer_photo?.slice(
                -1
              )[0]?.fileLink || ""
            }
            isUpload={
              journeyData?.co_customer_documents?.co_customer_photo?.length > 0
            }
            status={
              journeyData?.co_customer_documents?.co_customer_photo?.slice(
                -1
              )[0]?.isApproved
            }
            data={
              journeyData?.co_customer_documents?.co_customer_photo?.slice(
                -1
              )[0]
            }
            isLoading={loading === "co_customer_photo"}
            setFile={(file) => {
              handleUpload(file, "co_customer_photo", true);
            }}
          />
        </Grid>
        {/* <Grid item xs={6} sm={6}>
          <DocumentCard
            text={l("Driving License", lang)}
            icon={licenseIco}
            isRequired={true}
            tempState={
              journeyData?.co_customer_documents?.co_customer_dl?.slice(-1)[0]
                ?.fileLink || ""
            }
            isUpload={
              journeyData?.co_customer_documents?.co_customer_dl?.length > 0
            }
            status={
              journeyData?.co_customer_documents?.co_customer_dl?.slice(-1)[0]
                ?.isApproved
            }
            data={
              journeyData?.co_customer_documents?.co_customer_dl?.slice(-1)[0]
            }
            isLoading={loading === "co_customer_dl"}
            setFile={(file) => {
              handleUpload(file, "co_customer_dl", true);
            }}
          />
        </Grid> */}
        {/* <Grid item xs={6} sm={6}>
          <DocumentCard
            text={"Electricity Bill"}
            isRequired={true}
            icon={electricityIco}
            tempState={
              journeyData?.co_customer_documents?.co_customer_electricity_bill?.slice(
                -1
              )[0]?.fileLink || ""
            }
            isUpload={
              journeyData?.co_customer_documents?.co_customer_electricity_bill
                ?.length > 0
            }
            status={
              journeyData?.co_customer_documents?.co_customer_electricity_bill?.slice(
                -1
              )[0]?.isApproved
            }
            isLoading={loading === "co_customer_electricity_bill"}
            setFile={(file) => {
              handleUpload(file, "co_customer_electricity_bill", true);
            }}
          />
        </Grid> */}
        <Grid item xs={6} sm={6}>
          <DocumentCard
            text={l("House ownership proof(Electricity bill)", lang)}
            isRequired={true}
            icon={otherIco}
            tempState={
              journeyData?.co_customer_documents?.co_customer_ownership_proof?.slice(
                -1
              )[0]?.fileLink || ""
            }
            isUpload={
              journeyData?.co_customer_documents?.co_customer_ownership_proof
                ?.length > 0
            }
            status={
              journeyData?.co_customer_documents?.co_customer_ownership_proof?.slice(
                -1
              )[0]?.isApproved
            }
            data={
              journeyData?.co_customer_documents?.co_customer_ownership_proof?.slice(
                -1
              )[0]
            }
            isLoading={loading === "co_customer_ownership_proof"}
            setFile={(file) => {
              handleUpload(file, "co_customer_ownership_proof", true);
            }}
          />
        </Grid>
        {/* <Grid item xs={6} sm={6}>
          <DocumentCard
            text={"Voter ID Front"}
            icon={voterIdFront}
            tempState={
              journeyData?.co_customer_documents?.co_customer_voter_id_front?.slice(
                -1
              )[0]?.fileLink || ""
            }
            isUpload={
              journeyData?.co_customer_documents?.co_customer_voter_id_front
                ?.length > 0
            }
            status={
              journeyData?.co_customer_documents?.co_customer_voter_id_front?.slice(
                -1
              )[0]?.isApproved
            }
            isLoading={loading === "co_customer_voter_id_front"}
            setFile={(file) => {
              handleUpload(file, "co_customer_voter_id_front", true);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <DocumentCard
            text={"Voter ID Back"}
            icon={voterIdBack}
            tempState={
              journeyData?.co_customer_documents?.co_customer_voter_id_back?.slice(
                -1
              )[0]?.fileLink || ""
            }
            isUpload={
              journeyData?.co_customer_documents?.co_customer_voter_id_back
                ?.length > 0
            }
            status={
              journeyData?.co_customer_documents?.co_customer_voter_id_back?.slice(
                -1
              )[0]?.isApproved
            }
            isLoading={loading === "co_customer_voter_id_back"}
            setFile={(file) => {
              handleUpload(file, "co_customer_voter_id_back", true);
            }}
          />
        </Grid> */}
        {co_customer_other_documents?.map((doc, index) => {
          return (
            <Grid item xs={6} sm={6}>
              <DocumentCard
                text={
                  index === co_customer_other_documents?.length - 1
                    ? l("Add More", lang)
                    : doc?.name?.replaceAll("_", " ")
                }
                icon={addmoreIco}
                tempState={doc?.fileLink || ""}
                isUpload={index === co_customer_other_documents?.length - 1 ? false : true}
                status={doc?.isApproved}
                data={doc}
                isLoading={loading === "co_customer_other_documents"}
                setFile={(file) => {
                  handleUpload(file, "co_customer_other_documents", false);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CoCustomers;
