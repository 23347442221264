import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
// import HomeIcon from "@mui/icons-material/Home";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
// import { CgProfile } from "react-icons/cg";
import MenuAppBar from "../../components/Appbar";
import DemoHome from "./DemoHome";
import KycPop from "../../components/KycPop";
import { UserContext } from "../../Contexts/UserContext";
// import Profile from "../../";
// import LoanScreen from "../../pages/Loan";
import { useNavigate, useParams } from "react-router-dom";
import { getNotification, incentiveData } from "../../api/agent";
// import RewardWallet from "../../components/V2/Reward/RewardWallet";
// import RewardsFlyer from "./Rewards/RewardsFlyer";
// import MenuScreen from "../../pages/HomeScreens/Menu";
import MenuScreen from "../pages/Menu";
// import home_icon from "../../assets/home_icon.svg";
// import menu_icon from "../../assets/menu_icon.svg";
// import profile_icon from "../../assets/profile_icon.svg";
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import DealerProfile from './DealerProfile';
import ViewProfile from "../../pages/HomeScreens/ViewProfile";
import { GlobalContext } from "../../Contexts/GlobalContext";
// import BorrowerProfile from '../pages/HomeScreens/BorrowerProfile';


export default function FixedBottomNavigation() {
  const { tab } = useParams();
  const [index, setIndex] = useState();
  const [value, setValue] = useState(tab ? tab : "");
  const [openFlyer, setOpenFlyer] = useState(false);
  const { user } = useContext(UserContext);
  const [appText, setText] = useState("");
  const navigate = useNavigate();

  const { notiCountState } = useContext(GlobalContext);
  const [notiCount, setNotiCount] = notiCountState;

  const [coins, setCoins] = useState({});

  const ref = useRef(null);

  useEffect(() => {
    switch (index) {
      case 0:
        setText(<span>{`Hi, ${user?.name?.split(" ")[0]}`} <span class="wave">👋</span></span>);
        break;
      case 1:
        setText(`Loan Details`);
        break;
      case 2:
        setText(`Profile`);
        break;
    }
  }, [index]);

  useEffect(() => {
    switch (tab) {
      // case "loan":
      case "menu":
        setIndex(1);
        break;
      case "profile":
        setIndex(2);
        break;
      case "profile-view":
        setIndex(2);
        break;
      default:
        setIndex(0);
        break;
    }
  }, [tab]);

  const getCoinData = async () => {
    try {
      let { data } = await incentiveData(false, true, "");
      if (data?.status) {
        setCoins({
          application: data?.applicationIncentiveData?.total_coins,
          overall: data?.totalAgentIncentiveData?.total_coins,
        });
      }
    } catch (err) { }
  };

  useEffect(() => {
    getCoinData();
    const isFlyerShown = localStorage.getItem("is_flyer_shown");
    if ([null, undefined]?.includes(isFlyerShown)) {
      setOpenFlyer(true);
      localStorage.setItem("is_flyer_shown", true);
    }
  }, []);

  const getNotificationCount = async () => {
    try {
      let { data } = await getNotification(`count=true`);
      console.log('red')
      setNotiCount(data?.count || 0);
    } catch (err) { }
  };

  useEffect(() => {
    // getCoinData();
    const isFlyerShown = localStorage.getItem("is_flyer_shown");
    if ([null, undefined]?.includes(isFlyerShown)) {
      setOpenFlyer(true);
      localStorage.setItem("is_flyer_shown", true);
    }
    getNotificationCount();
  }, []);

  return (
    <>
      {/* <Box sx={{ pb: 7, pt: value === 0 ? 8 : 3 }} ref={ref}> */}
      <Box sx={{ pb: 0, pt: 0 }} ref={ref}>
        {index === 0 && (
          <MenuAppBar home={true} text={appText} active={index} />
        )}
        <CssBaseline />
        <div>
          {user?.kycStage === "none" && <KycPop />}
          {!tab && <DemoHome />}
          {/* {tab === "loan" && <LoanScreen />} */}
          {tab === "menu" && <MenuScreen />}
          {/* {tab === "profile" && <Profile />} */}
          {tab === "profile" && <DealerProfile />}
          {/* {tab === "profile" && <BorrowerProfile />} */}
          {tab === "profile-view" && <ViewProfile />}
        </div>
        <Paper
          sx={{
            height: "auto",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            pb: 1,
            pt: 1,
            // backgroundColor: "#573A7A",
            backgroundColor: "#fff",
            borderRadius: 0
          }}
          elevation={3}
        >
          <BottomNavigation
            className=""
            showLabels
            value={index}
            sx={{
              bgcolor: "#fff",
              "& .Mui-selected": {
                "& .MuiBottomNavigationAction-label": {
                  color: "white",
                  transition: "none",
                  lineHeight: "20px",
                },
                "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
                  color: "white",
                },
              },
            }}
            onChange={(event, newValue) => {
              setIndex(newValue);
              switch (newValue) {
                case 0:
                  setText(<span>{`Hi, ${user?.name?.split(" ")[0]}`} <span class="wave">👋</span></span>);
                  setValue("");
                  navigate("/home");
                  break;
                case 1:
                  setText(`Menu`);
                  setValue("menu");
                  navigate("/home/menu");
                  break;
                case 2:
                  setText(`Profile`);
                  setValue("profile");
                  navigate("/home/profile");
                  break;
              }
            }}
          >
            <BottomNavigationAction
              label={<span style={{ color: '#573A7A' }}>Home</span>}
              icon={
                <AiOutlineHome style={{ fontSize: "25", color: "#573A7A" }} />
                // <img src={home_icon} />
              }
            />
            <BottomNavigationAction
              label={<span style={{ color: '#573A7A' }}>Menu</span>}
              icon={
                <MenuIcon style={{ fontSize: "25", color: "#573A7A" }} />
                // <img src={menu_icon} />
              }
            />
            <BottomNavigationAction
              label={<span style={{ color: '#573A7A' }}>Profile</span>}
              icon={
                <PersonIcon style={{ fontSize: "25", color: "#573A7A" }} />
                // <img src={profile_icon} />
              }
            />
            {/* <BottomNavigationAction
            label="Profile"
            icon={<CgProfile style={{ fontSize: "25" }} />}
          /> */}
          </BottomNavigation>
        </Paper>
      </Box>

      {/* {user?.incentives_enabled && <RewardWallet coin={coins?.overall} />} */}
      {/* <RewardsFlyer
        open={openFlyer}
        handleClose={() => {
          setOpenFlyer(false);
        }}
      /> */}
    </>
  );
}
