import React, { useContext } from "react";
import MenuAppBar from "../../components/Appbar";
import {
  Avatar,
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Skeleton,
} from "@mui/material";
import { Col, Container, FormControl, Row } from "react-bootstrap";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getNotification, markReadNotification } from "../../api/agent";
import { useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { UserContext } from "../../Contexts/UserContext";
import { l } from "../../util/languageConvertor";
import notiIco from "../../assets/notiIcon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import MixPanelAudit from "../../util/MixPanelAudit";
const NotificationScreen = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tokenState, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const { user } = useContext(UserContext);
  const [notification, setNotification] = useState([]);
  const [tokenData, setTokenData] = tokenState;
  const [loading, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const handleBack = () => {
    navigate("/home");
  };
  console.log("user name", user);
  const fetchNotifications = async (page) => {
    setLoading(true);
    try {
      let { data } = await getNotification(`data=true&page=${page}`);
      setNotification((prev) => [...prev, ...data?.notifications]);
      console.log("count", Math.ceil(data?.totalCount / 1));
      setTotalCount(Math.ceil(data?.totalCount / 1));

      // markRead();
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.message || "Cannot load notification. Try again"
      );
    }
    setLoading(false);
  };

  const markRead = async () => {
    try {
      let { data } = await markReadNotification();
    } catch (err) {
      console.log(err?.response?.data?.message);
    }
  };
  useEffect(() => {
    markRead();
  }, []);

  const NotiCard = ({ val, index }) => {
    const [img, setImg] = useState(val?.notification?.image);
    return (
      <div
        className={`notification-card ${
          !val?.send_to?.[index]?.is_read && "notification-not-read"
        }`}
        onClick={() => {
          console.log(val?.type, val?.data?.application_id);
          if (val?.type === "NBFC_ASSIGNED" && val?.data?.application_id) {
            navigate(
              `/create-loan/documents-upload/${val?.data?.application_id}`
            );
          } else if (val?.type === "UPLOAD_RC" && val?.data?.application_id) {
            navigate(`/create-loan/loan-status/${val?.data?.application_id}`);
          } else if (
            val?.type === "SEND_COURIER" &&
            val?.data?.application_id
          ) {
            navigate(`/create-loan/loan-status/${val?.data?.application_id}`);
          } else if (
            val?.type === "LOAN_DISBURSED" &&
            val?.data?.application_id
          ) {
            navigate(`/create-loan/loan-status/${val?.data?.application_id}`);
          } else if (
            val?.type === "PENDING_DOCUMENTS" &&
            val?.data?.application_id
          ) {
            navigate(
              `/create-loan/documents-upload/${val?.data?.application_id}/3`,
              { state: { pendingStatus: true } }
            );
          } else if (
            val?.type === "DOCUMENT_REJECTED" &&
            val?.data?.application_id
          ) {
            navigate(
              `/create-loan/documents-upload/${val?.data?.application_id}/3`,
              {
                state: {
                  checkStatus: true,
                  notification: "RejectedNotification",
                },
              }
            );
          }
          MixPanelAudit.intercept("notification_click", {
            notiifcation_headline: val?.notification?.title,
            source_by: "Notification Tab",
            agent_name: user?.name,
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <p className="noti-heading">{val?.notification?.title}</p>
            <p className="noti-body">{val?.notification?.body}</p>
            <p className="noti-time">{moment(val?.createdAt).fromNow()}</p>
          </Grid>
          <Grid item xs={4} className="d-flex justify-content-end">
            {/* <Avatar
                            src={val?.notification?.image}
                            sx={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                            }}
                          >
                            <NotificationsNoneIcon sx={{ fontSize: 45 }} />
                          </Avatar> */}

            <img
              src={img || ""}
              width={"100px"}
              onError={() => setImg(notiIco)}
              height={"100px"}
              style={{
                objectFit: "contain",
                marginRight: 10,
                borderRadius: 30,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const LoadingComp = () => {
    return ["", "", ""].map((val) => {
      return (
        <>
          <Container
            className="load-collapse-row collapse-blue pointer"
            style={{ height: "120px" }}
          >
            <Row className="mt-2">
              <Col className="font-blue">
                <Skeleton width={"100%"} height={"200%"} />
              </Col>
              <Col className="font-blue">
                <Skeleton width={"100%"} height={"200%"} />
              </Col>
              <Col className="font-blue">
                <Skeleton width={"100%"} height={"200%"} />
              </Col>
            </Row>
          </Container>
        </>
      );
    });
  };

  useEffect(() => {
    fetchNotifications(page);
  }, []);
  return (
    <div className="content-center">
      <Box className="w-100 kyc-wrapper" sx={{ pb: 7, pt: 7 }}>
        <MenuAppBar
          home={false}
          action={handleBack}
          bigText={l("Notifications", lang)}
        />
        <CssBaseline />
        <div className="main-wrapper w-100">
          <>
            <InfiniteScroll
              dataLength={notification.length}
              next={() => {
                setPage(page + 1);
                fetchNotifications(page + 1);
              }}
              hasMore={notification?.length < totalCount}
              loader={<LoadingComp />}
              endMessage={
                isLoading ? (
                  <LoadingComp />
                ) : (
                  notification?.length !== 0 && (
                    <p style={{ textAlign: "center", color: "gray" }}>
                      {l("Loaded all the Notification", lang)} 👍
                    </p>
                  )
                )
              }
            >
              <>
                {notification?.map((val) => {
                  const index = val?.send_to?.findIndex(
                    (item) => item?.recipient_id === user?.agent_code
                  );

                  return <NotiCard val={val} index={index} />;
                })}
              </>
            </InfiniteScroll>
          </>
        </div>
      </Box>
    </div>
  );
};

export default NotificationScreen;
