import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
// import { requestOTPAadhar, submitOTPAadhar } from "../../api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { kycContext } from "../../Contexts/KycContext";
import { requestOTPAadhar, submitOTPAadhar } from "../../api";

const AadharVerifications = ({ setActive }) => {
  const { kycDataState, aadharVerifState } = useContext(kycContext);

  const [kycData, setKycData] = kycDataState;
  const [isAadharVerified, setAadharVerfiy] = aadharVerifState;

  let inputs = [];

  const [otp, setOtp] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [timer, setTimer] = useState("10:00");
  const { enqueueSnackbar } = useSnackbar();

  const [verifyResponse, setVerifyResponse] = useState({
    verification_id: ""
  });

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);
  const input6 = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    console.log(kycData?.aadhar_number);
    startTimer(10 * 60);
    requestOTP();
  }, []);

  const handleInput = (target) => {
    if (/^\d+$/.test(target.value)) {
      getOTP();
      let nextInput = target.nextElementSibling;

      if (nextInput && target.value) {
        nextInput.focus();
        nextInput.value && nextInput.select();
      }
    } else {
      target.value = "";
    }
  };

  const handleBackspace = (target) => {
    getOTP();
    return target.value
      ? (target.value = "")
      : target.previousElementSibling?.focus();
  };

  const handleArrowLeft = (target) => {
    const previousInput = target.previousElementSibling;
    return !previousInput ? undefined : previousInput.focus();
  };
  const handleArrowRight = (target) => {
    const nextInput = target.nextElementSibling;
    return !nextInput ? undefined : nextInput.focus();
  };
  const handlePaste = (event, inputs) => {
    let pasteText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    inputs.forEach((input, index) => {
      input.value = pasteText[index] || ``;
    });
    getOTP();
    event.preventDefault();
  };

  function shortcut(element, key, handle, params) {
    element.addEventListener(`keydown`, (e) => {
      if(e.target.value!=="") e.preventDefault()
      return e.key.toString().toLowerCase() == key && handle(element);
    });
  }

  useEffect(() => {
    const verification = document.querySelector(`.verification`);
    const inputs = [
      input1.current,
      input2.current,
      input3.current,
      input4.current,
      input5.current,
      input6.current,
    ];

    verification.addEventListener("input", ({ target }) => handleInput(target));
    verification.addEventListener("paste", (e) => handlePaste(e, inputs));

    shortcut(inputs[0], `backspace`, handleBackspace);
    shortcut(inputs[1], `backspace`, handleBackspace);
    shortcut(inputs[2], `backspace`, handleBackspace);
    shortcut(inputs[3], `backspace`, handleBackspace);
    shortcut(inputs[4], `backspace`, handleBackspace);
    shortcut(inputs[5], `backspace`, handleBackspace);

    shortcut(inputs[0], `arrowleft`, handleArrowLeft);
    shortcut(inputs[1], `arrowleft`, handleArrowLeft);
    shortcut(inputs[2], `arrowleft`, handleArrowLeft);
    shortcut(inputs[3], `arrowleft`, handleArrowLeft);
    shortcut(inputs[4], `arrowleft`, handleArrowLeft);
    shortcut(inputs[5], `arrowleft`, handleArrowLeft);

    shortcut(inputs[0], `arrowright`, handleArrowRight);
    shortcut(inputs[1], `arrowright`, handleArrowRight);
    shortcut(inputs[2], `arrowright`, handleArrowRight);
    shortcut(inputs[3], `arrowright`, handleArrowRight);
    shortcut(inputs[4], `arrowright`, handleArrowRight);
    shortcut(inputs[5], `arrowright`, handleArrowRight);
  }, []);

  const getOTP = () => {
    const inputs = [
      input1.current.value,
      input2.current.value,
      input3.current.value,
      input4.current.value,
      input5.current.value,
      input6.current.value,
    ];
    let temp =
      inputs[0] + inputs[1] + inputs[2] + inputs[3] + inputs[4] + inputs[5];
    setOtp(temp);
  };

  useEffect(() => {
    if (validateOtp()) {
      submitOTP();
    }
  }, [otp]);

  const validateOtp = () => {
    if (otp.length === 6 && /^\d+$/.test(otp)) {
      return true;
    }
    return false;
  };

  const requestOTP = async () => {
    try {
      console.log("Request");
      let { data } = await requestOTPAadhar({
        aadhar_number: kycData?.aadhar_number,
      });

      if (data?.status) {
        setVerifyResponse((pr) => ({
          ...pr,
          verification_id: data.data?.verificationId
        }));
      }
    } catch (err) {
      setLoading(false)
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.log(err);
    }
  };

  const submitOTP = async () => {
    try {
      console.log("submit");
      setLoading(true);
      let body = {
        verification_id: verifyResponse.verification_id,
        aadhaar_number: kycData?.aadhar_number,
        otp,
      };

      let { data: submitResponse } = await submitOTPAadhar(body);
      if (submitResponse?.validated_aadhar) {
        enqueueSnackbar("Aadhar verified successfully", { variant: "success" });
        setAadharVerfiy(true);
        setActive(1);
      }

     
    } catch (err) {
      enqueueSnackbar("Something went wrong. Try again", { variant: "error" });
      setActive(1);
      setLoading(false);
      console.log(err);
    }
  };

  const handleResend = () => {
    setOtp("");
    input1.current.value = "";
    input2.current.value = "";
    input3.current.value = "";
    input4.current.value = "";
    input5.current.value = "";
    input6.current.value = "";
    enqueueSnackbar("OTP resent successfully", { variant: "success" });
    requestOTP();
  };

  function startTimer(duration, display) {
    var timer = duration,
      minutes,
      seconds;
    setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setTimer(minutes + ":" + seconds);

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }


  return (
    <Box className="aadhaar-wrapper">
      <div className={`appbar-wrapper`}>
        <div className="appbar">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ArrowBackIcon
                className="ms-3"
                onClick={() => {
                  setActive(1);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="verification-wrapper">
        <Grid container spacing={2} className="pb-5">
          <Grid item xs={12} sm={12}>
            <div className="verification_head">
              <p className="main-text">Verify Aadhar</p>
              <p className="desc" style={{ fontWeight: "500" }}>
                An OTP is send to the mobile number linked with your aadhar
                number.<br></br>
                <span style={{ fontSize: "10px" }}>
                  Please ensure your mobile number is linked.
                </span>
              </p>
            </div>
            <form class="verification w-100">
              <input
                ref={input1}
                type="number"
                autocomplete="false"
                class="verification__input verification__input_aadhar verification__input--1"
                id="verification-input-1"
                placeholder="-"
                maxLength="1"
                inputMode="numeric"
                autoComplete="off"
              />
              <input
                ref={input2}
                type="number"
                autocomplete="false"
                class="verification__input verification__input_aadhar verification__input--2"
                id="verification-input-2"
                placeholder="-"
                maxLength="1"
                inputMode="numeric"
                autoComplete="off"
              />
              <input
                ref={input3}
                type="number"
                autocomplete="false"
                class="verification__input verification__input_aadhar verification__input--3"
                id="verification-input-3"
                placeholder="-"
                maxLength="1"
                inputMode="numeric"
                autoComplete="off"
              />
              <input
                ref={input4}
                type="number"
                class="verification__input verification__input_aadhar verification__input--4"
                id="verification-input-4"
                autocomplete="false"
                placeholder="-"
                maxLength="1"
                inputMode="numeric"
                autoComplete="off"
              />

              <input
                ref={input5}
                type="number"
                class="verification__input verification__input_aadhar verification__input--5"
                id="verification-input-5"
                placeholder="-"
                maxLength="1"
                inputMode="numeric"
                autoComplete="off"
              />

              <input
                ref={input6}
                type="number"
                class="verification__input verification__input_aadhar verification__input--6"
                id="verification-input-6"
                placeholder="-"
                maxLength="1"
                inputMode="numeric"
                autoComplete="off"
              />
            </form>
            <div className="verification_footer">
              <p className="duration">({timer})</p>
              <p className="info">
                This session will end in 10 minutes.
                <br />
                {/* Didn’t receive a code?{" "}
                <span
                  className="pointer"
                  style={{ fontWeight: "500" }}
                  onClick={() => {
                    handleResend();
                  }}
                >
                  Resend Code
                </span> */}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="otp-btn-holder">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Button
              className={`btn-submit ${
                !isLoading && "btn-submit-disabled"
              }`}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              onClick={() => {}}
            >
              {!isLoading ? (
                "Verify"
              ) : (
                <>
                  <CircularProgress
                    className="me-2 mb-1"
                    size={20}
                    style={{ color: "white" }}
                  />
                  verifying
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default AadharVerifications;
