import React, { useContext, useEffect } from "react";
import loadingGif from "../../../../assets/loading.gif";
import docDone from "../../../../assets/docDone.svg";
import oopsIco from "../../../../assets/oops.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Button } from "@mui/material";
import LoadingOver from "../../../../components/LoadingOver";
import { useSnackbar } from "notistack";
import { updateEsignStatusApi } from "../../../../api/agent";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";

const RedirectionScreen = () => {
  const { application_id, type, tab } = useParams();
  const [status, setStatus] = useState("loading");
  const [isLoading, setLoading] = useState(false);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { search } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [digioData, setDigioData] = useState({
    status: "",
    message: "",
  });

  useEffect(() => {
    // setStatus('')
    const searchParams = new URLSearchParams(search);
    const status = searchParams.get("status");
    const message = searchParams.get("message");
    setStatus(status);
    setDigioData({
      status,
      message,
    });
    updateEsignStatus();
  }, [application_id, type]);

  const updateEsignStatus = async () => {
    setLoading(true);
    try {
      let payload = {
        application_id,
      };
      if (type === "customer") {
        payload = {
          ...payload,
          customer: true,
        };
      } else {
        payload = {
          ...payload,
          co_customer: true,
        };
      }
      let { data } = await updateEsignStatusApi(payload);
    } catch (error) {
        console.log(error);
      enqueueSnackbar(
        error?.response?.data?.message || "Updating status failed",
        {
          variant: "error",
        }
      );
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mt-5">
        <p className="text-center" style={{ marginTop: "200px" }}>
          {status === "loading" && <img src={loadingGif} alt="" width={50} />}
          {status === "success" && <img src={docDone} alt="" width={70} />}
          {status === "failed" && <img src={oopsIco} alt="" width={70} />}
        </p>
        <p
          className="text-center"
          style={{ color: "#573A7A", fontSize: "30px" }}
        >
          {status === "loading" && "Fetching Response"}
          {status === "success" && "Successfully!!"}
          {status === "failed" && "Failed!!"}
        </p>
        {["success", "failed"].includes(status) && <p>{digioData?.message}</p>}

        <p className="text-center">
          <Button
            className="btn-submit mt-2"
            onClick={() => {
              navigate(`/create-loan/documents-upload/${application_id}/${tab}`);
            }}
          >
            Go Back
          </Button>
        </p>
      </div>
      {isLoading && <LoadingOver text={"Updating Status"} />}
    </>
  );
};

export default RedirectionScreen;
