import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import { agentLogin, mobileLogin, verifyAuth } from "../../../api/agent";
import { useSnackbar } from "notistack";
import { GlobalContext } from "../../../Contexts/GlobalContext";

export default function useAuth() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { setUser } = useContext(UserContext);
  const { otpState } = useContext(GlobalContext);

  const [error, setError] = useState(null);
  const [otpData, setOtpData] = otpState;

  // const loginAgent = async (agent_code) => {

  //   let payload = {
  //       agent_code,
  //   };
  //   try {

  //     let { data } = await agentLogin(payload);
  //     setUser({...data.data, type:"AGENT"})

  //   } catch (error) {
  //     console.log(error.response.data?.message);
  //     enqueueSnackbar(error.response.data?.message || "Server down", {
  //       variant: "error",
  //     });
  //   }
  // };

  const loginUser = async (mobile) => {
    
    let payload = {
      mobile_number: mobile,
    };

    try {
      let { data } = await mobileLogin(payload);
      if (data?.status) {

        console.log(data);
        
        setOtpData((st) => ({
          ...st,
          mobile: mobile,
          path: "home",
          method: "customer-login",
          vId: data?.verificationId,
        }));

        return true

        // navigate(`/otp/${data?.verificationId}`);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message || "Server Down", {
        variant: "error",
      });
      return false;
    }
  };



  return {
    loginUser
    // loginAgent,
    // error,
  };
}
