import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import Paper from "@mui/material/Paper";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
// import HomeIcon from "@mui/icons-material/Home";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { GiReceiveMoney } from "react-icons/gi";
// import { AiOutlineHome } from "react-icons/ai";
// import { CgProfile } from "react-icons/cg";
// import MenuAppBar from "../../../components/Appbar";
// import KycPop from "../../../components/KycPop";
import { useSnackbar } from "notistack";
import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Skeleton,
  InputLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { CustomerContext } from "../../Contexts/CustomerContext";
import SearchIcon from "@mui/icons-material/Search";
import emptyIco from "../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import SelectCom from "../../../components/Select";
import { fetchAllApplications, fetchDashboardData, getAllApplicationWithFilter, searchApplication } from "../../api/agent";
import RightArrow from "../../assets/arrow-right.svg";
import useApplication from "../../hooks/useApplication";
// import { ShowBorrower, TimeOptions } from "../../../constants/Filter";
import { cf } from "../util/CurrencyFormatter";
import { l } from "../../util/languageConvertor";


const FilterCard = ({ count, onClick, text, filter, value }) => {
  return (
    <Grid>
      <div style={{
        borderRadius: '10px',
        padding: "10px 30px",
        backgroundColor: filter === value ? "#573A7A" : "#F9F3FF",
        color: filter === value ? "#fff" : "#573A7A"
      }}
        onClick={() => onClick(value)}
      >
        {count}
      </div>
      <div style={{
        display: "flex", justifyContent: "center"
      }}>

        <p style={{
          fontWeight: "700",
          fontSize: "11px",
          color: "#573A7A"
        }}>{text}</p>
      </div>
    </Grid>
  )
}

export default function AmountCredited() {
  const ref = useRef(null);
  const selectRef = useRef(null);
  const enqueueSnackbar = useSnackbar();
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [status, setStatus] = useState("All");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState(false);
  const [time, setTime] = useState("Any time");
  const [filter, setFilter] = useState("All Applications");
  const [dashboardData, setDashboardData] = useState("");
  const [amtCreditCardData, setAmtCreditCardData] = useState({});

  const navigate = useNavigate();

  const { showPermissionState, applicationState, pathState, langState } =
    useContext(GlobalContext);
  const [_, setSelectedApplication] = applicationState;
  const [applicationPath, setAppPath] = pathState;
  const [lang, setLang] = langState;

  const { detailState, vehicleState } = useContext(CustomerContext);
  const [vehicleDetails, setVehicleDetails] = vehicleState;

  const fetchData = async () => {
    setLoading(true);
    let temp = [];
    try {
      let timeQuery = {};
      // if (time === "Any time") {
      //   timeQuery = { type: "All", value: 1 };
      // } else {
      //   timeQuery = {
      //     type: time.split(" ")[1].toLowerCase(),
      //     value: time.split(" ")[0],
      //   };
      // }
      let { data } = await fetchAllApplications(
        filter.split(" ")[0]?.toLowerCase(),
        1,
        500
      );

      // console.log(data?.data[0]?.result?.data);
      // data?.data[0]?.result?.data?.forEach((edx) => {
      //   if (ShowBorrower?.includes(edx?.application_id)) {
      //     temp = [...temp, edx];
      //   }
      // });
      console.log('amount credited api res : ', data?.data[0]?.result?.data)
      setApplications(data?.data[0]?.result?.data);
      // setApplications(temp);
    } catch (error) { }
    setLoading(false);
  };

  const fetchAllApplicationWithFilter = async () => {
    try {
      let { data } = await getAllApplicationWithFilter('disbursed');
      console.log('amount credited api res : ', data);
      setApplications(data?.data);
      setAmtCreditCardData(data?.total_loan_amount);
    }
    catch (err) {
      console.log('amount credited error : ', err)
    }
  }

  useEffect(() => {
    // fetchData();
    fetchAllApplicationWithFilter();
  }, [filter]);

  // useEffect(() => {
  //   window.onpopstate = () => {
  //     navigate("/home/loan");
  //   };
  // });

  let timeOut;

  useEffect(() => {
    if (searchTxt !== "") {
      clearTimeout(timeOut);
      setLetSearch(false);
      timeOut = setTimeout(() => {
        setLetSearch(true);
      }, 1000);
    }
  }, [searchTxt]);

  useEffect(() => {
    searchTxt !== "" && setSearching(true);

    if (letSearch) {
      if (searchTxt !== "") {
        (async () => {
          let timeQuery = {};
          // if (time === "Any time") {
          //   timeQuery = { type: "All", value: 1 };
          // } else {
          //   timeQuery = {
          //     type: time.split(" ")[1].toLowerCase(),
          //     value: time.split(" ")[0],
          //   };
          // }

          let { data } = await searchApplication(searchTxt, 500);
          if (data?.status) {
            setApplications(data?.data[0]?.result?.data);
          }
        })();
      } else {
        fetchData(status);
      }
      setSearching(false);
    }
  }, [letSearch]);

  const getStatus = (val) => {
    if (val?.embifi_approval_details?.status) {
      if (val?.is_disbursed) {
        if (val?.is_closed) {
          return <span style={{ color: "#000000" }}>Closed</span>;
        } else {
          return <span style={{ color: "#00A945" }}>Disbursed</span>;
        }
      } else {
        return <span style={{ color: "#00A945" }}>Approved</span>;
      }
    } else if (val?.embifi_approval_details?.status === null) {
      return <span style={{ color: "#9B319F" }}>Pending</span>;
    } else {
      return <span style={{ color: "#F13131" }}>Rejected</span>;
    }
  };

  const handleChangeFilter = (val) => {
    setFilter(val)
  }

  const getDashboardData = async () => {
    try {
      setLoading(true);
      let { data } = await fetchDashboardData();
      console.log(data);
      setDashboardData(data?.data);
    } catch (error) {
      enqueueSnackbar(error?.message || "Error loading dashboard data", { variant: "error" });
    }
    setLoading(false);
  };

  const { getStage } = useApplication();


  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    console.log('--- amtCreditCardData ', amtCreditCardData != null)
  }, [amtCreditCardData])


  return (
    <div className="content-center bg-new">
      <Box sx={{ pb: 7 }} ref={ref} className="response-wrapper">
        <Grid container spacing={2} className="p-3 pt-0" style={{
          boxShadow: "none",
          border: "none",
          position: "sticky",
          top: 0,
          backgroundColor: "#F9F8FF",
          zIndex: 100
        }}>
          <Grid item xs={12} sm={12} >
            <div className="page-head-secondary">
              <ArrowBackIcon
                sx={{ color: "#573A7A" }}
                className="pointer"
                onClick={() => {
                  navigate("/home/menu");
                }}
              />{" "}
              {l("Amount Credited", lang)}
              <Grid sx={{ display: "flex", justifyContent: "space-around" }} item xs={12} sm={12}>
                <Grid item xs={12} sm={12} className="search-spacing">
                  <div style={{ position: "relative" }}>
                    <SearchIcon
                      className="search-ico"
                      style={{ color: "gray" }}
                      onClick={() => { }}
                    />

                    <input
                      type={"text"}
                      placeholder="Search Application"
                      class="form-input input-blue input-blue-search w-100"
                      value={searchTxt}
                      onChange={(e) => {
                        setSearchTxt(e.target.value);
                      }}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={3} sm={3}>
                  <FormControl size="small" xs={4} fullWidth>
                    <InputLabel sx={{
                      color: "#573A7A",
                    }} id="demo-simple-select-label">Time</InputLabel>
                    <Select size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{
                        borderRadius: "10px"
                      }}
                      // value={age}
                      label="Time"
                      onChange={(e) => setTime(e.target.value)}
                    >
                      {
                        TimeOptions.map((edx, index) => <MenuItem value={edx} key={index}>{edx}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              <Grid sx={{
                display: "flex", justifyContent: 'space-around', marginTop: "20px"
              }} xs={12}>
                <div className="amt-card">
                  <p className="ac-amt">{cf(amtCreditCardData?.totalLoanAmount)}</p>
                  <p className="ac-label">{l("Total Amount Credited", lang)}</p>
                </div>

                <div className="amt-card">
                  <p className="ac-amt">{cf(amtCreditCardData?.lastThirtyDaysLoanAmount)}</p>
                  <p className="ac-label">{l("Recent Amount Credited", lang)}</p>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="p-3 pt-0">
          <Grid item xs={12} sm={12}>

            {isSearching && (
              <p className="w-100 text-center mt-2 mb-2">
                <CircularProgress size={25} />
              </p>
            )}

            {!isLoading ? (
              <>
                {applications.length !== 0 &&
                  applications.map((val) => {
                    return (
                      // val?.creation_stage === "DISBURSED" && 
                      (
                        <>
                          <Container
                            className="load-collapse-row collapse-shadow pointer"
                            onClick={() => {
                              // getStage(
                              //   val?.creation_stage,
                              //   val?.application_id,
                              //   val?.coCustomerData ? true : false
                              // );
                              navigate('/borrower-profile', {
                                state: {
                                  customer: val,
                                  origin: "/amount-credited"
                                }
                              })
                            }}
                          >
                            <Row className="mt-2">
                              <Col className="font-blue">
                                <div className="w-100">
                                  <div style={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                  }}>
                                    {val?.customerData?.is_corporate ? val?.customerData?.corporate_pan_details?.name : val?.customerData?.pan_details?.name || "-----"}
                                  </div>
                                  <div style={{
                                    fontWeight: 400,
                                    fontSize: "10px",
                                  }}>
                                    Credit Date :
                                    {/* {val?.customerData?.pan_details?.name} */}
                                    {val?.disbursed_date
                                      ? moment(val?.disbursed_date).format("DD/MM/YYYY")
                                      : "---"}
                                  </div>
                                  {/* <div style={{ fontSize: "10px" }}>
                                  {val?.customer_id}
                                </div> */}
                                </div>
                              </Col>
                              {/* <Col style={{maxWidth: "30%",overflow: "hidden"}} className="font-blue">{val?.customerData?.aadhaar_details?.name || "-----"}</Col> */}
                              {/* <Col className="font-blue">{val?.nbfc_id}</Col> */}
                              <Col className="font-blue align-right">
                                <div className="w-100" >
                                  <div style={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    display: "flex",
                                    justifyContent: 'flex-end'

                                  }}>
                                    {/* {getStatus(val)} */}
                                    <span style={{
                                      fontWeight: 400,
                                      fontSize: "10px",
                                    }}>{cf(val?.loan_amount)}</span>
                                  </div>

                                  <div style={{
                                    display: "flex",
                                    justifyContent: 'flex-end'
                                  }}>
                                    <img src={RightArrow} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {filter === "Rejected Applications" && (
                              <Row className="">
                                <Col style={{ fontSize: 10, color: "red" }}>
                                  {/* {val?.anchorData?.name} */}
                                  {val?.embifi_approval_details?.remark}

                                </Col>
                              </Row>
                            )}
                          </Container>
                        </>)
                    );
                  })}
                {applications.length === 0 && (
                  <p className="text-center mt-5">
                    <img src={emptyIco} />
                    <p style={{ color: "gray" }}>{l("No Applications here", lang)}</p>
                  </p>
                )}{" "}
              </>
            ) : (
              <>
                {["", "", ""].map((val) => {
                  return (
                    <>
                      <Container className="load-collapse-row collapse-blue pointer">
                        <Row className="mt-2">
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                        </Row>
                      </Container>
                    </>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
