import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import CaptureOnly from "../../components/CaptureOnly";
import CaptureUpload from "../../components/CaptureV2";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FiFileText } from "react-icons/fi";
import { kycContext } from "../../Contexts/KycContext";
import { validateAadhar, validatePAN } from "../../validations/docValidations";
import { isBlank } from "../../validations/fieldValidations";
import CaptureSelfie from "../../components/FaceDetect/CaptureOnly";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const Documents = forwardRef((props, ref) => {
  const { kycDataState, aadharVerifState, poaBackState, poaFrontState } =
    useContext(kycContext);
  const [kycData, setKycData] = kycDataState;
  const [isAadharVerified, setAadharVerfiy] = aadharVerifState;

  const [poaFront, setproofFront] = poaFrontState;
  const [poaBack, setproofBack] = poaBackState;

  const [pUploadError, setPError] = useState(false);
  const [pErrorTxt, setPErrorTxt] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    businessName: "",
    businessAddress: "",
    selfie: false,
    sitePhoto: false,
    poaFront: false,
    poaBack: false,
    aadhar_number: "",
    pan_number: "",
    pan_image: "",
  });

  const [panResponse, setPanResponse] = useState({
    avail: false,
    msg: "",
  });

  useEffect(() => {
    if (poaFront !== "")
      setKycData((st) => ({ ...st, aadhar_front: poaFront }));
    if (poaBack !== "") setKycData((st) => ({ ...st, aadhar_back: poaBack }));
  }, [poaFront, poaBack]);

  const isValid = () => {
    let valid = true;

    let errors = {
      businessName: isBlank(kycData.businessName),
      businessAddress: isBlank(kycData.businessAddress),
    };

    setFieldErrors((err) => ({ ...err, ...errors }));

    for (let i in errors) {
      if (errors[i] !== "") {
        valid = false;
      }
    }

    if (kycData.selfie === "") {
      setFieldErrors((err) => ({ ...err, selfie: true }));
      valid = false;
    }
    if (kycData.sitePhoto === "") {
      setFieldErrors((err) => ({ ...err, sitePhoto: true }));
      valid = false;
    }
    if (kycData.isAadharNum) {
      if (
        kycData.aadhar_number === "" ||
        validateAadhar(kycData.aadhar_number) !== ""
      ) {
        setFieldErrors((err) => ({
          ...err,
          aadhar_number: "Invalid aadhar number",
        }));
        valid = false;
      }
    } else {
      if (kycData.aadhar_front === "") {
        setFieldErrors((err) => ({ ...err, poaFront: true }));
        valid = false;
      }
      if (kycData.aadhar_back === "") {
        setFieldErrors((err) => ({ ...err, poaBack: true }));
        valid = false;
      }
    }

    if (kycData.isPanNum) {
      if (!panResponse.avail) {
        validatePAN(kycData.panNumber, setPanResponse, "PPAN");
        valid = false;
      }
    } else {
      if (kycData.panFront === "") {
        valid = false;
        setPError(true);
      }
    }

    return valid;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      if (isValid()) {
        return true;
      } else {
        console.log("Not valid");
      }
    },

    gotoAdhaarVerification() {
      if (validateAadhar(kycData.aadhar_number) === "") {
        return true;
      }
      setFieldErrors((er) => ({
        ...er,
        aadhar_number: validateAadhar(kycData.aadhar_number),
      }));
      return false;
    },
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <label className="form-label">
          Dealership Name <span className="mand-star">*</span>
        </label>
        <input
          className="form-input w-100"
          autoComplete="given-name"
          name="businessName"
          required
          fullWidth
          placeholder="Enter your Dealer Name"
          value={kycData.businessName}
          onChange={(e) => {
            setFieldErrors((err) => ({ ...err, businessName: "" }));
            setKycData((st) => ({ ...st, businessName: e.target.value }));
          }}
        />
        <span className="error">{fieldErrors.businessName}</span>
      </Grid>

      <Grid item xs={12} sm={12}>
        <label className="form-label">
          Business Address <span className="mand-star">*</span>
        </label>
        <input
          className="form-input w-100"
          autoComplete="given-name"
          name="businessAddress"
          required
          fullWidth
          placeholder="Enter your Business Address"
          value={kycData.businessAddress}
          onChange={(e) => {
            setFieldErrors((err) => ({ ...err, businessAddress: "" }));
            setKycData((st) => ({ ...st, businessAddress: e.target.value }));
          }}
        />
        <span className="error">{fieldErrors.businessAddress}</span>
      </Grid>

      <Grid item xs={12} sm={12}>
        <CaptureOnly
          text="Selfie"
          error={fieldErrors.selfie}
          setFile={(file) => {
            setKycData((prev) => ({ ...prev, selfie: file }));
          }}
          selfie={true}
          tempState={kycData.selfie}
          getLocation={(location) => {
            const { lat, long } = location;
            setKycData((st) => ({
              ...st,
              selfieLocation: { lat, long },
            }));
          }}
          onSelect={() => {
            setFieldErrors((p) => ({ ...p, selfie: "" }));
          }}
        />

        {/* <CaptureSelfie
          text="Selfie"
          error={fieldErrors.selfie}
          setFile={(file) => {
            setKycData((prev) => ({ ...prev, selfie: file }));
          }}
          selfie={true}
          tempState={kycData.selfie}
          getLocation={(location) => {
            const { lat, long } = location;
            setKycData((st) => ({
              ...st,
              selfieLocation: { lat, long },
            }));
          }}
          onSelect={() => {
            setFieldErrors((p) => ({ ...p, selfie: "" }));
          }}
        /> */}
      </Grid>

      <Grid item xs={12} sm={12}>
        <CaptureOnly
          text="Business Site Photo"
          backDefault={true}
          setFile={(file) => {
            setKycData((prev) => ({ ...prev, sitePhoto: file }));
          }}
          tempState={kycData.sitePhoto}
          error={fieldErrors.sitePhoto}
          getLocation={(location) => {
            const { lat, long } = location;
            setKycData((st) => ({ ...st, siteLocation: { lat, long } }));
          }}
          onSelect={() => setFieldErrors((p) => ({ ...p, panFront: "" }))}
        />
        <span className="error">{fieldErrors.panFront}</span>
      </Grid>

      <Grid item xs={12} sm={12}>
        <div className="capture-only pointer" onClick={() => {}}>
          <div className="capture-content-v2" style={{ border: "0" }}>
            <FiFileText
              className="me-2"
              style={{ color: "rgba(108, 99, 255, 1)" }}
            />
            <span className="input-placeholder-capture">
              Pan Number <span className="mand-star">*</span>
            </span>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} className="pt-0">
        <RadioGroup
          className="mb-1"
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={kycData.isPanNum}
          name="radio-buttons-group"
          onChange={(e) => {
            if (e.target.value === "true") {
              setKycData((st) => ({
                ...st,
                panFront: "",
              }));
            }
            setKycData((prev) => ({
              ...prev,
              isPanNum: e.target.value === "true" ? true : false,
            }));
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={5} sm={5}>
              <FormControlLabel
                value={true}
                control={<Radio sx={radioStyle} />}
                label={<span style={{ fontSize: "10px" }}>PAN Number</span>}
                sx={labelStyle}
              />
            </Grid>

            <Grid item xs={7} sm={7}>
              <FormControlLabel
                value={false}
                control={<Radio sx={radioStyle} />}
                label={
                  <span style={{ fontSize: "10px" }}>
                    Upload / Capture Pan Card
                  </span>
                }
                sx={labelStyle}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>

      {/* ////////////////////////// PAN //////////////////////////////// */}

      {kycData.isPanNum && (
        <Grid item xs={12} sm={12} className="input-pan">
          <input
            className="form-input w-100"
            autoComplete="given-name"
            name="pannumber"
            maxlength="10"
            required
            fullWidth
            placeholder="Enter your Personal PAN"
            value={kycData.panNumber}
            onChange={(e) => {
              if (
                /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)
              )
                return;
              setKycData((st) => ({
                ...st,
                panNumber: e.target.value.toUpperCase(),
              }));
              if (e.target.value.length >= 10) {
                validatePAN(
                  e.target.value.toUpperCase(),
                  setPanResponse,
                  "PPAN"
                );
              }
              if (e.target.value.length > 0 && e.target.value.length < 10) {
                setPanResponse((p) => ({ ...p, avail: false, msg: "" }));
              }
            }}
            onBlur={(e) => {
              validatePAN(e.target.value.toUpperCase(), setPanResponse, "PPAN");
            }}
          />
          {!panResponse.avail && !panResponse.loading && (
            <span className="error f-13">{panResponse.msg}</span>
          )}
        </Grid>
      )}
      {!kycData.isPanNum && (
        <Grid item xs={12} sm={12}>
          <CaptureUpload
            error={pUploadError}
            errorTxt={pErrorTxt}
            setFile={(file) => {
              setPError(false);
              setPErrorTxt("");
              setKycData((prev) => ({ ...prev, panFront: file }));
            }}
            tempState={kycData.panFront}
          />
        </Grid>
      )}

      {/* ///////////////////////////////////////////////////////////////// */}

      <Grid item xs={12} sm={12}>
        <div className="capture-only pointer" onClick={() => {}}>
          <div className="capture-content-v2" style={{ border: "0" }}>
            <FiFileText
              className="me-2"
              style={{ color: "rgba(108, 99, 255, 1)" }}
            />
            <span className="input-placeholder-capture">
              Aadhar Card <span className="mand-star">*</span>
            </span>
          </div>
        </div>
      </Grid>

      {!isAadharVerified && (
        <Grid item xs={12} sm={12} className="pt-0">
          <RadioGroup
            className="mb-1"
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={kycData.isAadharNum}
            name="radio-buttons-group"
            onChange={(e) => {
              if (e.target.value === "true") {
                setKycData((st) => ({
                  ...st,
                  aadhar_back: "",
                  aadhar_front: "",
                }));
              } else {
                setKycData((st) => ({
                  ...st,
                  aadhar_number: "",
                }));
                setFieldErrors((er) => ({ ...er, aadhar_number: "" }));
              }
              setKycData((prev) => ({
                ...prev,
                isAadharNum: e.target.value === "true" ? true : false,
              }));
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={5} sm={5}>
                <FormControlLabel
                  value={true}
                  control={<Radio sx={radioStyle} />}
                  label={
                    <span style={{ fontSize: "10px" }}>Aadhar Number</span>
                  }
                  sx={labelStyle}
                />
              </Grid>

              <Grid item xs={7} sm={7}>
                <FormControlLabel
                  value={false}
                  control={<Radio sx={radioStyle} />}
                  label={
                    <span style={{ fontSize: "10px" }}>
                      Upload / Capture Aadhar
                    </span>
                  }
                  sx={labelStyle}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      )}

      {kycData.isAadharNum && (
        <Grid item xs={12} sm={12} sx={{ mb: "70px" }} className="input-pan">
          <input
            type="number"
            className="form-input w-100 mb-1"
            required
            disabled={isAadharVerified}
            fullWidth
            placeholder="Enter your Aadhar number"
            name="primary-number"
            autoComplete="family-name"
            value={kycData.aadhar_number}
            onKeyDown={(e) => {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            }}
            onChange={(e) => {
              if (e.target.value.length > 12) return false;
              setKycData((st) => ({
                ...st,
                aadhar_number: e.target.value,
              }));
            }}
            onKeyUp={(e) =>
              setFieldErrors((st) => ({
                ...st,
                aadhar_number: validateAadhar(e.target.value),
              }))
            }
          />
          {isAadharVerified && (
            <CheckCircleIcon
              className="pan-success"
              style={{ color: "green" }}
            />
          )}

          <span className="error">{fieldErrors.aadhar_number}</span>
        </Grid>
      )}

      {!kycData.isAadharNum && (
        <>
          <Grid item xs={12} sm={12}>
            <label className="form-label">
              Aadhar Card Front <span className="mand-star">*</span>
            </label>
            <CaptureUpload
              error={fieldErrors.poaFront}
              setFile={setproofFront}
              onSelect={() => setFieldErrors((p) => ({ ...p, poaFront: "" }))}
              tempState={poaFront}
            />
            {/* <span className="form-desc">Note: Upto 5 Mb Only</span> */}
            <span className="error">{fieldErrors.poaFront}</span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <label className="form-label">
              Aadhar Card Back <span className="mand-star">*</span>
            </label>
            <CaptureUpload
              error={fieldErrors.poaBack}
              setFile={setproofBack}
              onSelect={() => setFieldErrors((p) => ({ ...p, poaBack: "" }))}
              tempState={poaBack}
            />
            <span className="error"></span>
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default Documents;
