import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import uploadedIco from "../../assets/uploaded.svg";
import rejectedIco from "../../assets/rejectedIco.svg";
import approvedIco from "../../assets/approved.svg";

import { FiCamera, FiImage, FiX } from "react-icons/fi";
import { GrGallery } from "react-icons/gr";

import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";
import { compressImage, getBase64 } from "../../util/convertFileBase";
import { CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import ImageCropper from "../Crop";
import PermissionModal from "../PermissionModal";
import usePermission from "../../hooks/usePermission";
import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CropScreen from "../CropScreen";
import { useNavigate } from "react-router-dom";
import RejectionPopup from "../V3/RejectionPopup";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DocumentCard({
  setFile,
  error,
  errorTxt,
  onSelect,
  text,
  isRequired,
  tempState,
  disabled,
  icon,
  isUpload,
  status,
  isLoading,
  isButton,
  data,
  isCourier,
  onClickCard,
}) {
  const { showPermissionState } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isSelfie, setSelfie] = useState(false);
  const [captureImage, setImage] = useState("");
  const [confirmFilename, setConfirmFilename] = useState("");
  const [isInput, setInput] = useState(false);
  const [pdfFile, setPdf] = useState("");
  const [loading, setLoading] = React.useState(true);
  const { permissions, getUserLocation } = usePermission();
  const [showPermission, setShowPermission] = showPermissionState;

  const cameraInput = useRef();
  const fileInput = useRef();
  const sliderBottom = useRef();
  const backdrop = useRef();

  const [cropImgTemp, setTempCrop] = useState("");
  const [croppedImg, setCropImg] = useState("");
  const [isMobile, setMobile] = useState(false);

  const handleClickOpen = () => {
    // setOpen(true);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      fileInput.current.click();
      setInput(true);
    } else {
      setShowPermission((st) => ({ ...setConfirmFilename, state: false }));
    }
  };

  const handleClose = () => {
    // setOpen(false);
    setTimeout(() => {
      sliderBottom.current?.classList.add("close");
      backdrop.current?.classList.add("hide");
    }, 100);
  };

  const handleRecapture = () => {
    fileInput.current.value = "";
    setConfirmFilename("");
    setInput(false);
    setPdf("");
    setImage("");
    handleClickOpen();
    // setFile && setFile("");
  };

  useEffect(() => {
    if (!open) {
      setIsCameraOpen(false);
      setImage("");
    }
  }, [open]);

  useEffect(() => {
    if (croppedImg !== "") {
      setFile && setFile(croppedImg);
    }
  }, [croppedImg]);

  useEffect(() => {
    if (pdfFile !== "") {
      setFile && setFile(pdfFile);
    }
  }, [pdfFile]);

  // const onSelectFile = async (e) => {
  //   onSelect && onSelect();
  //   if (e.target.files && e.target.files.length > 0) {
  //     if (!e.target.files[0].type.match("application/pdf")) {
  //       setError && setError("Please upload pdf file only");
  //       return false;
  //     } else if (e.target.files[0].size >= 5 * 1024 * 1024) {
  //       setError && setError("Select files below 5mb");
  //       return;
  //     }

  //     setFileName(e.target.files[0].name);
  //     setFile(await getBase64(e.target.files[0]));
  //   }
  // };

  // useEffect(() => {
  //   console.log(tempState);
  // }, [tempState]);

  const handleUserMedia = () => setTimeout(() => setLoading(false), 1_000);

  useEffect(() => {
    setMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  // Menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    if (onClickCard) onClickCard();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ position: "relative", opacity: disabled ? 0.5 : 1 }}>
        {/* {status!==undefined && ( */}
        {isUpload && (
          <div
            className="doc-status-holder pointer"
            onClick={() => {
              if (!disabled) window.open(tempState, "_blank");
            }}
          >
            <img
              src={(() => {
                switch (status) {
                  case true:
                    return approvedIco;
                  case false:
                    return rejectedIco;
                  case null:
                    return uploadedIco;
                  default:
                    return uploadedIco;
                }
              })()}
              alt=""
              width={40}
              height={40}
            />
          </div>
        )}
        <>
          <div
            className={`documents-card-v3 ${
              !disabled && !(isButton || isCourier) && "pointer"
            } ${(isButton || isCourier) && "extra-height"}`}
            onClick={() => {
              if (!status && !disabled && !(isButton || isCourier)) {
                if (onClickCard) onClickCard();
                fileInput.current.click();
                setInput(true);
              }
            }}
          >
            <div>
              <p
                className={`mb-2 text-center ${
                  (isButton || isCourier) && "mt-3"
                }`}
              >
                <img
                  src={icon}
                  alt=""
                  width={60}
                  height={60}
                  style={{ objectFit: "contain" }}
                />
              </p>
              <p className="heading mb-0">
                {text} {isRequired && <span className="mand-star">*</span>}
              </p>
              {isButton && !status && (
                <div className={`w-100 mt-3 content-center`}>
                  {isMobile && (
                    <Button
                      className="capture-btn me-2"
                      onClick={() => {
                        if (onClickCard) onClickCard();
                        cameraInput.current.click();
                        setInput(true);
                      }}
                    >
                      <CameraAltIcon sx={{ fontSize: 12, mr: 0.5 }} /> Capture
                    </Button>
                  )}
                  <Button
                    className="upload-btn"
                    onClick={() => {
                      if (onClickCard) onClickCard();
                      fileInput.current.click();
                      setInput(true);
                    }}
                  >
                    <CloudUploadIcon sx={{ fontSize: 12, mr: 0.5 }} /> Upload
                  </Button>
                </div>
              )}

              {isCourier && !status && (
                <div className={`w-100 mt-3 content-center courier-reciept`}>
                  <Button
                    className="capture-btn me-2"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MailOutlineIcon sx={{ fontSize: 12, mr: 0.5 }} /> Send
                  </Button>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div style={{ width: 300, padding: 15 }}>
                      <Grid container spacing={1}>
                        {isMobile && (
                          <Grid item xs={6}>
                            <Button
                              className="courier-menu-btn"
                              onClick={() => {
                                handleCloseMenu();
                                cameraInput.current.click();
                                setInput(true);
                              }}
                            >
                              <CameraAltIcon sx={{ fontSize: 12, mr: 0.5 }} />{" "}
                              Capture
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={isMobile ? 6 : 12}>
                          <Button
                            className="courier-menu-btn"
                            onClick={() => {
                              handleCloseMenu();
                              fileInput.current.click();
                              setInput(true);
                            }}
                          >
                            <CloudUploadIcon sx={{ fontSize: 12, mr: 0.5 }} />{" "}
                            Upload
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            className="courier-menu-btn-fill"
                            onClick={() => {
                              handleCloseMenu();
                              setFile(null);
                            }}
                          >
                            <RemoveCircleOutlineIcon
                              sx={{ fontSize: 12, mr: 0.5 }}
                            />{" "}
                            Reciept not available
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Menu>
                </div>
              )}
            </div>
          </div>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                zIndex: 500,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(0, 0, 0, 0.31)",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 20,
              }}
            >
              <CircularProgress sx={{ color: "white" }} />
            </div>
          )}
        </>

        {status === false && data?.rejectionReason?.length > 0 && (
          <RejectionPopup message={data?.rejectionReason} />
        )}
      </div>

      <CropScreen
        open={captureImage !== "" && !isInput}
        image={captureImage}
        onClose={() => {
          setImage("");
        }}
        onCrop={(file) => {
          setCropImg(file);
          setConfirmFilename("capture.jpg");
          setImage("");
        }}
        onRecapture={() => {
          handleRecapture();
          fileInput.current.click();
        }}
      />

      <input
        ref={fileInput}
        type="file"
        class="custom-file-input"
        accept="application/pdf , image/png, image/jpg, image/jpeg"
        onChange={async (e) => {
          setCropImg("");
          onSelect && onSelect();
          if (e.target.files[0].type.match("application/pdf")) {
            setInput(true);
            // setPdf(await getBase64(e.target.files[0]));
            setPdf(await getBase64(e.target.files[0]));
            setConfirmFilename(e.target.files[0].name);
          } else if (e.target.files[0]["type"].split("/")[0] == "image") {
            if (e.target.files[0].type.match("image/svg")) {
              alert("Not supported file");
              return false;
            }
            setInput(false);
            setImage(await getBase64(await compressImage(e.target.files[0])));
          } else {
            alert("Pdf/Image only");
            handleRecapture();
          }
          e.target.value = "";
          handleClose();
        }}
        hidden
      />

      <input
        ref={cameraInput}
        accept="image/*"
        capture="environment"
        type="file"
        onChange={async (e) => {
          setCropImg("");
          onSelect && onSelect();
          if (e.target.files[0]["type"].split("/")[0] == "image") {
            if (e.target.files[0].type.match("image/svg")) {
              alert("Not supported file");
              return false;
            }
            setInput(false);
            setImage(await getBase64(await compressImage(e.target.files[0])));
          } else {
            alert("Image only");
            handleRecapture();
          }
          e.target.value = "";
          handleClose();
        }}
        hidden
      />
    </>
  );
}
