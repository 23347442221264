import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import {
  BottomNavigation,
  Button,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingOver from "../../../components/LoadingOver";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import CatureUpload from "../../../components/CaptureV2";
import CustomInputAutocomplete from "../../../components/AutoComplete";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import { createCoCustomer } from "../../../api/agent";
import { useSnackbar } from "notistack";
import useApplication from "../../../hooks/useApplication";
import CustomInputAutocomplete2 from "../../../components/AutoComplete2";
import UploadCard from "../../../components/V3/UploadCard";
import { l } from "../../../util/languageConvertor";
import ErrorMessage from "../../../components/V3/ErrorMessage";
import useErrorMessage from "../../../hooks/useErrorMessage";

export default function CoGuarantor() {
  const [isLoading, setLoading] = useState(false);

  const { application_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { showMessage, clearMessage } = useErrorMessage();

  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    // console.log(journeyData?.co_customer_relation);
    if (true) {
      let payload = {
        document_included_g: true,
        application_id,
        pan: journeyData?.co_customer_pan,
        aadhaar_front: journeyData?.co_customer_aadhaar_front,
        aadhaar_back: journeyData?.co_customer_aadhaar_back,
        isPanOcr: true,
        isGurantor:
          journeyData?.co_customer_type === "CO-APPLICANT" ? false : true,
        relation: journeyData?.co_customer_relation,
        start_time: Number(new Date()),
      };

      try {
        let { data } = await createCoCustomer(payload);
        if (data?.status) {
          let address = data?.data?.aadhaar_details?.address.split(",");

          setJourneyData({
            ...journeyData,
            co_customer_pan_number: data?.data?.pan_details?.number,
            co_customer_full_name: data?.data?.pan_details?.name,
            co_customer_aadhaar_address: data?.data?.aadhaar_details?.address,
            co_customer_aadhaar_pincode: address[address?.length - 1]?.trim(),
          });

          navigate("/create-loan/co-customer-details/" + application_id);
        }
      } catch (err) {
        showMessage(l(err?.response?.data?.message, lang));
        // enqueueSnackbar(l(err?.response?.data?.message, lang), {
        //   variant: "error",
        // });
      }
    }

    setLoading(false);
  };

  const { getApplicationDetails } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  return (
    <>
      <Box sx={{ pb: 1 }} className="w-100 response-wrapper">
        <div className="tab-fixed">
          <Grid container className="tabs-wrapper">
            <Grid className="app-bar" item xs={12} sm={12}>
              <ArrowBackIcon
                className="pointer ms-2"
                sx={{ color: "black" }}
                onClick={() => {
                  navigate("/draft-applications");
                }}
              />
            </Grid>
            <Grid
              className={`tab ${
                journeyData?.co_customer_type === "CO-APPLICANT" && "active"
              }`}
              item
              xs={6}
              sm={6}
              onClick={() => {
                clearMessage()
                setJourneyData({
                  ...journeyData,
                  co_customer_relation: "",
                  co_customer_type: "CO-APPLICANT",
                });
              }}
            >
              {l("Co-Applicant", lang)}
            </Grid>
            <Grid
              className={`tab ${
                journeyData?.co_customer_type === "GUARANTOR" && "active"
              }`}
              item
              xs={6}
              sm={6}
              onClick={() => {
                clearMessage();
                setJourneyData({
                  ...journeyData,
                  co_customer_relation: "",
                  co_customer_type: "GUARANTOR",
                });
              }}
            >
              {l("Guarantor", lang)}
            </Grid>
          </Grid>
        </div>

        {/* <div style={{ marginTop: "130px", padding: "20px" }}>sdsf</div> */}

        <div style={{ paddingTop: "100px", paddingBottom: "60px" }}>
          <ErrorMessage />
          <div className="main-wrapper w-100 mt-1">
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <label className="form-label">
                    {l("Relation with customer", lang)}{" "}
                    <span className="mand-star">*</span>
                  </label>
                  {journeyData?.co_customer_type !== "CO-APPLICANT" && (
                    // <input
                    //   type="text"
                    //   className="form-input w-100 px-3"
                    //   required
                    //   placeholder="Relation"
                    //   name="email"
                    //   autoComplete="family-name"
                    //   value={journeyData.co_customer_relation}
                    //   onChange={(e) => {
                    //     setJourneyData((st) => ({
                    //       ...st,
                    //       co_customer_relation: e.target.value,
                    //     }));
                    //   }}
                    //   onKeyUp={
                    //     (e) => {}
                    //     // setFieldErrors((st) => ({
                    //     //   ...st,
                    //     //   email: isEmailValid(e.target.value),
                    //     // }))
                    //   }
                    // />

                    <CustomInputAutocomplete2
                      text={l("Relation with customer", lang)}
                      options={["Relative", "Friend"]}
                      onChange={(val) => {
                        // setFieldErrors((prev) => ({ ...prev, bank: "" }));
                        // setAccountDetails((prev) => ({ ...prev, bank: val }));
                        // let bank = BanksList.find((el) => el.bankname === val);
                        // setIfsc(bank?.ifsc);
                        clearMessage()
                        setJourneyData((st) => ({
                          ...st,
                          co_customer_relation: val,
                        }));
                      }}
                    />
                  )}

                  {journeyData?.co_customer_type === "CO-APPLICANT" && (
                    <CustomInputAutocomplete
                      text={l("Relation with customer", lang)}
                      options={[
                        "Husband",
                        "Wife",
                        "Mother",
                        "Father",
                        "Son",
                        "Daughter",
                        "Sister",
                        "Brother",
                      ]}
                      onChange={(val) => {
                        // setFieldErrors((prev) => ({ ...prev, bank: "" }));
                        // setAccountDetails((prev) => ({ ...prev, bank: val }));
                        // let bank = BanksList.find((el) => el.bankname === val);
                        // setIfsc(bank?.ifsc);
                        clearMessage()
                        setJourneyData((st) => ({
                          ...st,
                          co_customer_relation: val,
                        }));
                      }}
                    />
                  )}

                  {/* <span className="error">{fieldErrors.email}</span> */}
                </Grid>

                <Grid item xs={12} sm={12} className="mb-3">
                  <UploadCard
                    text={l("Personal Pan", lang)}
                    isRequired={true}
                    setFile={(file) => {
                      clearMessage()
                      setJourneyData((st) => ({
                        ...st,
                        co_customer_pan: file,
                      }));
                    }}
                    tempState={journeyData.co_customer_pan}
                  />
                </Grid>

                <Grid item xs={12} sm={12} className="mb-3">
                  <UploadCard
                    text={l("Aadhaar front", lang)}
                    isRequired={true}
                    setFile={(file) => {
                      clearMessage()
                      setJourneyData((st) => ({
                        ...st,
                        co_customer_aadhaar_front: file,
                      }));
                    }}
                    tempState={journeyData.co_customer_aadhaar_front}
                  />
                </Grid>

                <Grid item xs={12} sm={12} className="mb-3">
                  <UploadCard
                    text={l("Aadhaar Back", lang)}
                    isRequired={true}
                    setFile={(file) => {
                      clearMessage()
                      setJourneyData((st) => ({
                        ...st,
                        co_customer_aadhaar_back: file,
                      }));
                    }}
                    tempState={journeyData.co_customer_aadhaar_back}
                  />
                </Grid>
              </Grid>
            </>
          </div>
        </div>

        <Paper className="paper-bottom" elevation={3}>
          <BottomNavigation style={{ height: "auto", paddingTop: "3px" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={
                  isLoading ||
                  journeyData?.co_customer_relation === "" ||
                  journeyData?.co_customer_aadhaar_front === "" ||
                  journeyData?.co_customer_aadhaar_back === "" ||
                  journeyData?.co_customer_pan === ""
                }
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Proceed", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>

      {isLoading && <LoadingOver />}
    </>
  );
}
