import { Button, CssBaseline, Grid } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import DocumentCard from "../../../../components/V3/DocumentCard";
import UploadCard from "../../../../components/V3/UploadCard";
import form26Ico from "../../../../assets/tabs/form26Ico.svg";
import salesInvoiceIco from "../../../../assets/tabs/salesInvoiceIco.svg";
import form22Ico from "../../../../assets/tabs/form22Ico.svg";
import deliveryIco from "../../../../assets/tabs/deliveryIco.svg";
import chasisIco from "../../../../assets/tabs/chasisIco.svg";
import warrantyIco from "../../../../assets/tabs/warrantyIco.svg";
import insuranceIco from "../../../../assets/tabs/insuranceIco.svg";
import form20Ico from "../../../../assets/tabs/form20Ico.svg";
import form21Ico from "../../../../assets/tabs/form21Ico.svg";
import quaotationIco from "../../../../assets/tabs/quaotationIco.svg";
import trcIco from "../../../../assets/tabs/trcIco.svg";
import { uploadDocument } from "../../../../api/agent";
import useApplication from "../../../../hooks/useApplication";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import MultiDocUpload from "../../../../components/V2/MultiDocUpload";
import ContinueUpload from "../../../../components/V2/ContinueUpload";
import SelectUpload from "../../../../components/V2/SelectUpload";
import MenuAppBar from "../../../../components/Appbar";
import { useNavigate } from "react-router-dom";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { l } from "../../../../util/languageConvertor";
import addmoreIco from "../../../../assets/tabs/addmoreIco.svg";

const VehicleDocuments = ({ application_id, openStatus }) => {
  const { user } = useContext(UserContext);
  const { journeyState } = useContext(LoanContext);
  const navigate = useNavigate();
  const [journeyData, setJourneyData] = journeyState;
  const { getApplicationDetails } = useApplication();
  const [multiData, setMultiData] = useState({ show: false });
  const [loading, setLoading] = useState("");
  const [vehicle_other_documents, setOtherDocs] = useState([]);

  const { langState, docPopupState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [docPopup, setDocPopup] = docPopupState;

  const handleUpload = async (file, name, overRide) => {
    setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        `?override=${overRide}`
      );

      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }

      getApplicationDetails(application_id, true);
    } catch (error) {}
    setLoading("");
  };

  useEffect(() => {
    console.log('@@@@ReachEd....', journeyData?.documents?.vehicle_other_documents);
    let doc = journeyData?.documents?.vehicle_other_documents;
    if (doc?.length > 0) {
      setOtherDocs([...doc, { name: `vehicle_other_documents_${doc?.length}` }]);
    } else {
      setOtherDocs([{ name: "vehicle_other_documents_1" }]);
    }
  }, [journeyData]);

  return (
    <>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
        leftContent={
          <Button
            color="secondary"
            onClick={() => {
              MixPanelAudit.intercept("check_status_click", {
                distinct_id: user?.mobile_number[0],
                agent_name: user?.name,
                application_id,
              });
              openStatus();
            }}
          >
            {l("Check Status", lang)}
          </Button>
        }
      />
      <CssBaseline />
      <div>
        <Grid container spacing={2} className="mt-1">
          <Grid item xs={6} sm={6}>
            <ContinueUpload
              icon={form26Ico}
              name={l(`Form 26-35`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 15,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "form_26_35",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "form_26_35", true);
              }}
              documents={journeyData?.documents?.form_26_35}
              started={journeyData?.documents?.form_26_35?.[0]}
              completed={journeyData?.documents?.form_26_35?.[0]?.completed}
              status={journeyData?.documents?.form_26_35?.[0]?.isApproved}
              pages={journeyData?.documents?.form_26_35?.length}
              isMulti={
                journeyData?.documents?.form_26_35?.[0]?.meta_page_document
              }
              setState={setMultiData}
              isLoading={loading === "form_26_35"}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Sales Invoice", lang)}
              icon={salesInvoiceIco}
              isRequired={true}
              tempState={
                journeyData?.documents?.sales_invoice?.slice(-1)[0]?.fileLink ||
                ""
              }
              isUpload={journeyData?.documents?.sales_invoice?.length > 0}
              status={
                journeyData?.documents?.sales_invoice?.slice(-1)[0]?.isApproved
              }
              data={journeyData?.documents?.sales_invoice?.slice(-1)[0]}
              isLoading={loading === "sales_invoice"}
              setFile={(file) => {
                handleUpload(file, "sales_invoice", true);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              icon={quaotationIco}
              text={l(`Quotation`, lang)}
              isRequired={true}
              tempState={
                journeyData?.documents?.quotation?.slice(-1)[0]?.fileLink ||
                ""
              }
              isUpload={journeyData?.documents?.quotation?.length > 0}
              status={
                journeyData?.documents?.quotation?.slice(-1)[0]?.isApproved
              }
              data={journeyData?.documents?.quotation?.slice(-1)[0]}
              isLoading={loading === "quotation"}
              setFile={(file) => {
                handleUpload(file, "quotation");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <ContinueUpload
              icon={form22Ico}
              name={l(`Form 22`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 2,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "form_22",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "form_22", true);
              }}
              documents={journeyData?.documents?.form_22}
              started={journeyData?.documents?.form_22?.[0]}
              completed={journeyData?.documents?.form_22?.[0]?.completed}
              status={journeyData?.documents?.form_22?.[0]?.isApproved}
              pages={journeyData?.documents?.form_22?.length}
              isMulti={journeyData?.documents?.form_22?.[0]?.meta_page_document}
              setState={setMultiData}
              isLoading={loading === "form_22"}
              isRequired={true}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Vehicle Delivery photo", lang)}
              isRequired={true}
              icon={deliveryIco}
              tempState={
                journeyData?.documents?.erick_delivery_photo?.slice(-1)[0]
                  ?.fileLink || ""
              }
              isUpload={
                journeyData?.documents?.erick_delivery_photo?.length > 0
              }
              status={
                journeyData?.documents?.erick_delivery_photo?.slice(-1)[0]
                  ?.isApproved
              }
              data={journeyData?.documents?.erick_delivery_photo?.slice(-1)[0]}
              isLoading={loading === "erick_delivery_photo"}
              setFile={(file) => {
                handleUpload(file, "erick_delivery_photo", true);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Chassis Number Photo", lang)}
              icon={chasisIco}
              isRequired={true}
              tempState={
                journeyData?.documents?.chassis_number?.slice(-1)[0]
                  ?.fileLink || ""
              }
              isUpload={journeyData?.documents?.chassis_number?.length > 0}
              status={
                journeyData?.documents?.chassis_number?.slice(-1)[0]?.isApproved
              }
              data={journeyData?.documents?.chassis_number?.slice(-1)[0]}
              isLoading={loading === "chassis_number"}
              setFile={(file) => {
                handleUpload(file, "chassis_number", true);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Battery Warranty Card", lang)}
              isRequired={true}
              icon={warrantyIco}
              tempState={
                journeyData?.documents?.battery_warranty?.slice(-1)[0]
                  ?.fileLink || ""
              }
              isUpload={journeyData?.documents?.battery_warranty?.length > 0}
              status={
                journeyData?.documents?.battery_warranty?.slice(-1)[0]
                  ?.isApproved
              }
              data={journeyData?.documents?.battery_warranty?.slice(-1)[0]}
              isLoading={loading === "battery_warranty"}
              setFile={(file) => {
                handleUpload(file, "battery_warranty", true);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Insurance", lang)}
              isRequired={true}
              icon={insuranceIco}
              tempState={
                journeyData?.documents?.insurance?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.insurance?.length > 0}
              status={
                journeyData?.documents?.insurance?.slice(-1)[0]?.isApproved
              }
              data={journeyData?.documents?.insurance?.slice(-1)[0]}
              isLoading={loading === "insurance"}
              setFile={(file) => {
                handleUpload(file, "insurance", true);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <ContinueUpload
              icon={form20Ico}
              name={l(`Form 20`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 5,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "form_20",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "form_20", true);
              }}
              documents={journeyData?.documents?.form_20}
              started={journeyData?.documents?.form_20?.[0]}
              completed={journeyData?.documents?.form_20?.[0]?.completed}
              status={journeyData?.documents?.form_20?.[0]?.isApproved}
              pages={journeyData?.documents?.form_20?.length}
              isMulti={journeyData?.documents?.form_20?.[0]?.meta_page_document}
              setState={setMultiData}
              isLoading={loading === "form_20"}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <ContinueUpload
              icon={form21Ico}
              name={l(`Form 21`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 5,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "form_21",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "form_21", true);
              }}
              documents={journeyData?.documents?.form_21}
              started={journeyData?.documents?.form_21?.[0]}
              completed={journeyData?.documents?.form_21?.[0]?.completed}
              status={journeyData?.documents?.form_21?.[0]?.isApproved}
              pages={journeyData?.documents?.form_21?.length}
              isMulti={journeyData?.documents?.form_21?.[0]?.meta_page_document}
              setState={setMultiData}
              isLoading={loading === "form_21"}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("TRC", lang)}
              isRequired={true}
              icon={trcIco}
              tempState={
                journeyData?.documents?.trc?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.trc?.length > 0}
              status={journeyData?.documents?.trc?.slice(-1)[0]?.isApproved}
              data={journeyData?.documents?.trc?.slice(-1)[0]}
              isLoading={loading === "trc"}
              setFile={(file) => {
                handleUpload(file, "trc", true);
              }}
            />
          </Grid>
          {vehicle_other_documents?.map((doc, index) => {
            return (
              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={
                    index === vehicle_other_documents?.length - 1
                      ? l("Add More", lang)
                      : doc?.name?.replaceAll("_", " ")
                  }
                  icon={addmoreIco}
                  tempState={doc?.fileLink || ""}
                  isUpload={index === vehicle_other_documents?.length - 1 ? false : true}
                  status={doc?.isApproved}
                  data={doc}
                  isLoading={loading === "vehicle_other_documents"}
                  setFile={(file) => {
                    handleUpload(file, "vehicle_other_documents", false);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>

      {multiData?.show && (
        <MultiDocUpload
          state={multiData}
          setState={setMultiData}
          onClose={() => {
            getApplicationDetails(application_id, true);
          }}
        />
      )}
    </>
  );
};

export default VehicleDocuments;
