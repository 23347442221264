import {
  BottomNavigation,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { forwardRef } from "react";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import MenuAppBar from "../../../components/Appbar";
import MultipleUpload from "../../../components/MultipleUpload";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import { useSnackbar } from "notistack";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  customerFieldInvestigation,
  fiSendOtp,
  updateFieldInvestigation,
} from "../../../api/agent";
import SuccessCard from "../../../components/SuccessCard";
import useApplication from "../../../hooks/useApplication";
import CustomInputAutocomplete from "../../../components/AutoComplete";
import CustomInputAutocomplete2 from "../../../components/AutoComplete2";
import CatureUpload from "../../../components/CaptureV3";
import { isBlank } from "../../../validations/fieldValidations";
import LoadingOver from "../../../components/LoadingOver";
import { l } from "../../../util/languageConvertor";
import OtpScreenOver from "../../OtpComponent";
import ImageLocation, {
  RenderCanvas,
} from "../../../components/V3/useImageWithLocation";
import useImageWithLocation from "../../../components/V3/useImageWithLocation";
import ErrorMessage from "../../../components/V3/ErrorMessage";
import useErrorMessage from "../../../hooks/useErrorMessage";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const labelHolder = { display: "flex", alignItems: "center" };

const FieldInvestigationV2 = forwardRef(({ setLoader }, ref) => {
  const { bottomNav, otpState, showRewardState, langState } =
    useContext(GlobalContext);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { enqueueSnackbar } = useSnackbar();
  const { application_id } = useParams();
  const navigate = useNavigate();
  const { showMessage, clearMessage } = useErrorMessage();
  const [isReward, showReward] = showRewardState;
  const [lang, setLang] = langState;
  const errorMessageRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [overLoading, setOverLoading] = useState(false);
  const [isAccepted, setAccepted] = useState(false);
  const [fieldDocs, setFieldDocs] = useState([]);
  const [errors, setError] = useState({
    total_members: "",
    earning_members: "",
    family_income: "",
    // engine_number: "",
    // chasis_no: "",
    customer_photo_with_vehicle: "",
    customer_house_inside: "",
    customer_house_outside: "",
    customer_photo_with_electricity_meter: "",
    reference1_name: "",
    reference1_mob: "",
    reference1_relation: "",
    reference2_name: "",
    reference2_mob: "",
    reference2_relation: "",
    premise_accessibility: "",
    fi_remarks: "",
    investigator_name: "",
    investigator_mobile: "",
  });
  const [alreadyUploaded, setAlreadyUploaded] = useState(null);

  const [otpData, setOtpData] = otpState;

  const refs = {
    total_members: useRef(),
    earning_members: useRef(),
    family_income: useRef(),
    engine_number: useRef(),
    chasis_no: useRef(),
    customer_photo_with_vehicle: useRef(),
    customer_house_inside: useRef(),
    customer_house_outside: useRef(),
    customer_photo_with_electricity_meter: useRef(),
    reference1_name: useRef(),
    reference1_mob: useRef(),
    reference1_relation: useRef(),
    reference2_name: useRef(),
    reference2_mob: useRef(),
    reference2_relation: useRef(),
    premise_accessibility: useRef(),
    fi_remarks: useRef(),
    investigator_name: useRef(),
    investigator_mobile: useRef(),
  };

  const removeItem = (index) => {
    setFieldDocs(
      fieldDocs.filter((val, i) => {
        if (i !== index) return val;
      })
    );
  };

  const isValid = () => {
    let valid = true;
    console.log(journeyData?.investigator_name);
    let errors = {
      total_members: (() => {
        if (journeyData?.total_members === "") {
          return "Cannot be blank";
        } else if (Number(journeyData?.total_members) < 1) {
          return l("Cannot be less than 1", lang);
        } else {
          return "";
        }
      })(),
      earning_members: (() => {
        if (journeyData?.earning_members === "") {
          return "Cannot be blank";
        } else if (Number(journeyData?.earning_members) < 1) {
          return l("Cannot be less than 1", lang);
        } else if (
          Number(journeyData?.earning_members) >
          Number(journeyData?.total_members)
        ) {
          return l("Cannot be greater than Total members", lang);
        } else {
          return "";
        }
      })(),
      family_income: isBlank(journeyData?.family_income),
      // engine_number: isBlank(journeyData?.engine_number),
      // chasis_no: isBlank(journeyData?.chasis_no),
      // customer_photo_with_vehicle: isBlank(
      //   journeyData?.customer_photo_with_vehicle
      // ),
      // customer_house_inside: isBlank(journeyData?.documents?.customer?.[0].uploadStatus),
      customer_house_inside: isBlank( alreadyUploaded?.['customer_house_inside']?.isApproved || journeyData?.customer_house_inside),
      customer_house_outside: isBlank( alreadyUploaded?.["customer_house_outside"]?.isApproved || journeyData?.customer_house_outside),
      customer_photo_with_electricity_meter: isBlank(
        alreadyUploaded?.["customer_photo_with_electricity_meter"]?.isApproved || journeyData?.customer_photo_with_electricity_meter
      ),
      reference1_name: isBlank(journeyData?.reference1_name),
      reference1_mob:
        journeyData?.reference1_mob.length !== 10
          ? "Invalid Mobile Number"
          : "",
      reference1_relation: isBlank(journeyData?.reference1_relation),
      reference2_name: isBlank(journeyData?.reference2_name),
      reference2_mob:
        journeyData?.reference2_mob.length !== 10
          ? "Invalid Mobile Number"
          : "",
      reference2_relation: isBlank(journeyData?.reference2_relation),
      premise_accessibility: isBlank(journeyData?.premise_accessibility),
      fi_remarks: isBlank(journeyData?.fi_remark),
      investigator_name: isBlank(journeyData?.investigator_name),
      investigator_mobile:
        journeyData?.investigator_mobile?.length !== 10
          ? "Invalid Mobile Number"
          : "",
    };

    console.log(errors);

    // if (journeyData?.engine_number === journeyData?.chasis_no) {
    //   errors = {
    //     ...errors,
    //     engine_number: "Engine Number must not be same as Chasis Number",
    //   };
    //   valid = false;
    // }

    // if (
    //   journeyData?.chasis_no.length !== 17 ||
    //   !/.*?(?:[a-zA-Z].*?[0-9]|[0-9].*?[a-zA-Z]).*?/.test(
    //     journeyData?.chasis_no
    //   )
    // ) {
    //   errors = { ...errors, chasis_no: "Invalid Chasis Number" };
    //   valid = false;
    // }

    // if (
    //   !/.*?(?:[a-zA-Z].*?[0-9]|[0-9].*?[a-zA-Z]).*?/.test(
    //     journeyData?.engine_number
    //   )
    // ) {
    //   errors = { ...errors, engine_number: "Invalid Engine Number" };
    //   valid = false;
    // }

    if (journeyData?.reference1_mob === journeyData?.reference2_mob) {
      errors = {
        ...errors,
        reference2_mob: l("Mobile cannot be same as reference 1", lang),
      };
      refs["reference2_mob"]?.current?.focus();
      window.scrollTo(0, refs["reference2_mob"]?.current?.offsetTop - 100);
    }

    setError(errors);
    // if (state.full_name === "" || state.pan_number === "") valid = false;
    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }

    return valid;
  };

  const handleSubmit = async () => {
    if (!isValid()) {
      return;
    }

    if (!isAccepted) {
      enqueueSnackbar(l("Please accept the checkbox", lang));
      return;
    }
    setLoading(true);

    let docs = fieldDocs.map((val, i) => {
      return {
        file: val?.file,
        location: val?.location,
        name: `field_investigation_${i + 1}`,
        collection: "loan",
        catagory: "field_investigation",
      };
    });
    
    if(!alreadyUploaded?.[`customer_house_inside`]?.isApproved){
        docs.push({
          file: journeyData?.customer_house_inside,
          name: `customer_house_inside`,
          collection: "loan",
          catagory: "field_investigation",
          location: journeyData?.customer_house_inside_location   
        })
    }

    if(!alreadyUploaded?.[`customer_house_outside`]?.isApproved){
      docs.push({
        file: journeyData?.customer_house_outside,
        name: `customer_house_outside`,
        collection: "loan",
        catagory: "field_investigation",
        location: journeyData?.customer_house_outside_location,
      })
    }
    
    if(!alreadyUploaded?.[`customer_photo_with_electricity_meter`]?.isApproved){
      docs.push({
        file: journeyData?.customer_photo_with_electricity_meter,
        name: `customer_photo_with_electricity_meter`,
        collection: "loan",
        catagory: "field_investigation",
        location: journeyData?.customer_photo_with_electricity_meter_location,
      })
    }

    let payload = {
      application_id,
      document_included_g: true,
      docs,
      references: [
        {
          name: journeyData?.reference1_name,
          mobile_number: journeyData?.reference1_mob,
          relation_with_borrower: journeyData?.reference1_relation,
        },
        {
          name: journeyData?.reference2_name,
          mobile_number: journeyData?.reference2_mob,
          relation_with_borrower: journeyData?.reference2_relation,
        },
      ],
      family: {
        family_members: journeyData?.total_members,
        earning_members: journeyData?.earning_members,
        amount_earned: journeyData?.family_income,
      },
      accessbility_till_premise: journeyData?.premise_accessibility,
      remarks: journeyData?.fi_remark,
      vehicle_engine_no: journeyData?.engine_number,
      vehicle_chasis_no: journeyData?.chasis_no,
      name: journeyData?.investigator_name,
      mobile_number: journeyData?.investigator_mobile,
    };

    console.log("payload --> ",payload);
    setJourneyData({
      ...journeyData,
      fi_payload: payload,
    });

    try {
      let { data } = await fiSendOtp(
        journeyData?.investigator_mobile,
        application_id,
        {
          references: payload?.references,
        }
      );
      console.log(data);
      if (data?.status) {
        enqueueSnackbar(l("Otp sent to mobile", lang), {
          variant: "success",
        });
        setOtpData((st) => ({
          ...st,
          mobile: journeyData?.investigator_mobile,
          path: "/create-loan/documents-upload/" + application_id + "/2",
          method: "field-investigation",
          vId: data?.verificationId,
          application_id,
          verified: false,
          open: true,
          other_data: {
            references: payload?.references,
          },
        }));
      }
    } catch (error) {
      showMessage(error?.response?.data?.message);
      scrollToRef();
      // enqueueSnackbar(error?.response?.data?.message, {
      //   variant: "error",
      // });
    }

    // try {
    //   let { data } = await customerFieldInvestigation(payload);
    //   if (data?.status) {
    //     setJourneyData({
    //       ...journeyData,
    //       loaded: false,
    //     });
    //     enqueueSnackbar("Documents added succesfully", {
    //       variant: "success",
    //     });
    //     navigate("/create-loan/progress/" + application_id);
    //   }
    // } catch (error) {
    //   enqueueSnackbar(error?.response?.data?.message, {
    //     variant: "error",
    //   });
    // }

    setLoading(false);
  };

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  // useEffect(() => {
  //   if (otpData?.verified && journeyData?.fi_payload) {
  //     (async () => {
  //       setLoader(true);
  //       try {
  //         let { data } = await updateFieldInvestigation(
  //           journeyData?.fi_payload
  //         );
  //         if (data?.status) {
  //           if (data?.is_coin_credited) {
  //             showReward({
  //               show: true,
  //               coin: data?.coin,
  //             });
  //           }
  //           setJourneyData({
  //             ...journeyData,
  //             loaded: false,
  //           });
  //           enqueueSnackbar("Field Investigation Updated", {
  //             variant: "success",
  //           });
  //           setJourneyData({
  //             ...journeyData,
  //             is_field_investigation_done: true,
  //           });
  //         }
  //       } catch (error) {
  //         enqueueSnackbar(error?.response?.data?.message, {
  //           variant: "error",
  //         });
  //       }
  //       setLoader(false);
  //     })();
  //   }
  // }, [otpData]);
  useEffect(()=>{
    let docs = {}
    journeyData?.documents?.customer?.map(item=>{
      docs[item?.name] = item;
    })
    setAlreadyUploaded(docs);
    console.log("journey documents --> ",docs?.["customer_inside_house"]?.isApproved);
  },[journeyData?.customer])

  const handleSubmitFi = async () => {
    setLoader(true);
    try {

      // journeyData.documents = journeyData?.documents?.customer?.fitler(item=>!item.isApproved);

      let { data } = await updateFieldInvestigation(journeyData?.fi_payload);
      if (data?.status) {
        if (data?.is_coin_credited) {
          showReward({
            show: true,
            coin: data?.coin,
          });
        }
        setJourneyData({
          ...journeyData,
          loaded: false,
        });
        enqueueSnackbar(l("Field Investigation Updated", lang), {
          variant: "success",
        });
        setJourneyData({
          ...journeyData,
          is_field_investigation_done: true,
        });
      }
    } catch (error) {
      showMessage(error?.response?.data?.message);
      scrollToRef();
      // enqueueSnackbar(error?.response?.data?.message, {
      //   variant: "error",
      // });
    }
    setLoader(false);
  };

  const scrollToRef = () => {
    errorMessageRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    clearMessage();
  }, [journeyData]);

  return (
    <>
      <div ref={errorMessageRef}>
        <ErrorMessage />
      </div>
      <Box className="w-100 mt-2" ref={ref}>
        <MenuAppBar
          home={false}
          action={() => {
            navigate(`/draft-applications`);
          }}
          shadow={true}
        />
        <CssBaseline />
        <div className="main-wrapper p-0">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Borrower", lang)}</label>
            </Grid>

            <Grid item xs={5} sm={3} className="py-0">
              <label className="form-label-black">
                {l("Customer Name", lang)} :
              </label>
            </Grid>
            <Grid item xs={7} sm={9} className="py-0">
              <label className="form-label">{journeyData?.full_name}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">{l("Address", lang)} :</label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">
                {journeyData?.current_address}
              </label>
            </Grid>

            <Grid item xs={6} sm={3} className="py-0">
              <label className="form-label-black">
                {l("Premises Ownership", lang)} :
              </label>
            </Grid>
            <Grid item xs={6} sm={7} className="py-0">
              <label className="form-label">
                {journeyData?.is_property_owned ? "Yes" : "No"}
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                {l("Total Number Of family members", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.total_members}
                type="text"
                inputMode="numeric"
                className="form-input w-100 px-3 white-bg"
                required
                placeholder={l("Total Number Of family members", lang)}
                style={{ fontSize: "14px" }}
                name="email"
                autoComplete="family-name"
                value={journeyData.total_members}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value?.[0] === "0") return false;
                  setError({ ...errors, total_members: "" });
                  // if (e.target.value.length > 3) return false;
                  // if (e.target.value < 1) return false;
                  setJourneyData((st) => ({
                    ...st,
                    total_members: e.target.value,
                  }));
                }}
              />
              <span className="error">{errors.total_members}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                {l("Number of earning members in family", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.earning_members}
                type="text"
                inputMode="numeric"
                className="form-input w-100 px-3 white-bg"
                required
                style={{ fontSize: "14px" }}
                placeholder={l("Number of earning members in family", lang)}
                name="earning"
                autoComplete="family-name"
                value={journeyData.earning_members}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value?.[0] === "0") return false;
                  // if (e.target.value > journeyData.total_members) return false;
                  setError({ ...errors, earning_members: "" });
                  // if (e.target.value.length > 3) return false;
                  setJourneyData((st) => ({
                    ...st,
                    earning_members: e.target.value,
                  }));
                }}
              />
              <span className="error">{errors.earning_members}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                {l("Annual family income (In Rs)", lang)}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.family_income}
                type="text"
                inputMode="numeric"
                className="form-input w-100 px-3 white-bg"
                required
                placeholder={l("Annual family income (In Rs)", lang)}
                style={{ fontSize: "14px" }}
                name="income"
                autoComplete="family-name"
                value={journeyData.family_income}
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value?.[0] === "0") return false;
                  setError({ ...errors, family_income: "" });
                  // if (e.target.value.length > ) return false;
                  setJourneyData((st) => ({
                    ...st,
                    family_income: e.target.value,
                  }));
                }}
              />
              <span className="error">{errors.family_income}</span>
            </Grid>

            {/* ////////// Dealer ////////// */}

            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Dealer", lang)}</label>
            </Grid>

            <Grid item xs={4} sm={2} className="py-0">
              <label className="form-label-black">
                {l("Dealer Name", lang)} :
              </label>
            </Grid>
            <Grid item xs={8} sm={10} className="py-0">
              <label className="form-label">{journeyData?.anchor_name}</label>
            </Grid>

            {/* ////////// Vehicle details ////////// */}

            {/* <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Vehicle Details", lang)}</label>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label">
                {l("Vehicle Engine No.", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.engine_number}
                type="text"
                className="form-input w-100 px-3 white-bg"
                required
                placeholder={l("Vehicle Engine No.", lang)}
                style={{ fontSize: "14px" }}
                name="engine_number"
                autoComplete="family-name"
                value={journeyData.engine_number}
                onChange={(e) => {
                  setError({ ...errors, engine_number: "" });
                  setJourneyData((st) => ({
                    ...st,
                    engine_number: e.target.value.replace(/[^\w\s]/gi, ""),
                  }));
                }}
              />
              <span className="error">{errors.engine_number}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label">
                {l("Vehicle Chasis no", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.chasis_no}
                type="text"
                className="form-input w-100 px-3 white-bg"
                required
                placeholder={l("Vehicle Chasis no", lang)}
                style={{ fontSize: "14px" }}
                name="chasis_no"
                autoComplete="family-name"
                value={journeyData.chasis_no}
                onChange={(e) => {
                  if (e.target.value.length > 17) return false;
                  setError({ ...errors, chasis_no: "" });
                  setJourneyData((st) => ({
                    ...st,
                    chasis_no: e.target.value.replace(/[^\w\s]/gi, ""),
                  }));
                }}
              />
              <span className="error">{errors.chasis_no}</span>
            </Grid> */}

            {/* ////////// Photos ////////// */}

            <Grid item xs={12} sm={12}>
              <label className="form-head">{l("Photos", lang)}</label>
            </Grid>

            <Grid item xs={12} sm={12}>
              {/* <CatureUpload
                ref={refs?.customer_photo_with_vehicle}
                tempState={journeyData?.customer_photo_with_vehicle}
                nostyle={true}
                error={errors?.customer_photo_with_vehicle}
                text={
                  <span>
                    Customer photo with vehicle{" "}
                    <span className="mand-star"> *</span>
                  </span>
                }
                isLocation={true}
                setFile={(file, location) => {
                  setError({ ...errors, customer_photo_with_vehicle: "" });
                  setJourneyData({
                    ...journeyData,
                    customer_photo_with_vehicle: file,
                    customer_photo_with_vehicle_location: location,
                  });
                }}
                // onSelect={() => {
                //   setFieldErrors((p) => ({ ...p, selfie: "" }));
                // }}
              /> */}
              <CatureUpload
                ref={refs?.customer_house_inside}
                nostyle={true}
                tempState={journeyData?.customer_house_inside}
                error={errors?.customer_house_inside}
                text={
                  <span>
                    {l("Customer House Inside", lang)}
                    <span className="mand-star"> *</span>
                  </span>
                }
                isLocation={true}
                setFile={(file, location) => {
                  setError({ ...errors, customer_house_inside: "" });
                  setJourneyData({
                    ...journeyData,
                    customer_house_inside: file,
                    customer_house_inside_location: location,
                  });
                }}
                disabled={alreadyUploaded?.[`customer_house_inside`]?.isApproved}
                // onSelect={() => {
                //   setFieldErrors((p) => ({ ...p, selfie: "" }));
                // }}
              />
              <CatureUpload
                ref={refs?.customer_house_outside}
                nostyle={true}
                tempState={journeyData?.customer_house_outside}
                error={errors?.customer_house_outside}
                text={
                  <span>
                    {l("Customer House Outside", lang)}
                    <span className="mand-star"> *</span>
                  </span>
                }
                isLocation={true}
                setFile={(file, location) => {
                  setError({ ...errors, customer_house_outside: "" });
                  setJourneyData({
                    ...journeyData,
                    customer_house_outside: file,
                    customer_house_outside_location: location,
                  });
                }}
                // onSelect={() => {
                //   setFieldErrors((p) => ({ ...p, selfie: "" }));
                // }}
                disabled={alreadyUploaded?.[`customer_house_outside`]?.isApproved}
              />
              <CatureUpload
                ref={refs?.customer_photo_with_electricity_meter}
                nostyle={true}
                tempState={journeyData?.customer_photo_with_electricity_meter}
                error={errors?.customer_photo_with_electricity_meter}
                text={
                  <span>
                    {l("Customer photo with electricity meter", lang)}
                    <span className="mand-star"> *</span>
                  </span>
                }
                isLocation={true}
                setFile={(file, location) => {
                  setError({
                    ...errors,
                    customer_photo_with_electricity_meter: "",
                  });
                  console.log("electricity meter --> ",file) 
                  setJourneyData({
                    ...journeyData,
                    customer_photo_with_electricity_meter: file,
                    customer_photo_with_electricity_meter_location: location,
                  });
                }}
                disabled={alreadyUploaded?.["customer_photo_with_electricity_meter"]?.isApproved}
                // onSelect={() => {
                //   setFieldErrors((p) => ({ ...p, selfie: "" }));
                // }}
              />
            </Grid>

            {/* ///////////// Add More ///////////////// */}

            <Grid item xs={12} sm={12}>
              {fieldDocs.map((val, i) => {
                let type = val?.file
                  .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
                  .split("/")[1];
                return (
                  <>
                    <CardHeader
                      avatar={
                        type !== "pdf" ? (
                          <img src={val?.file} width="50px" height="50px" />
                        ) : (
                          <InsertDriveFileIcon
                            sx={{
                              color: "rgba(108, 99, 255, 1)",
                              fontSize: "40px",
                            }}
                          />
                        )
                      }
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={() => removeItem(i)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      }
                      title={`File-${i + 1}`}
                      subheader={`file type: ${type}`}
                    />
                  </>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={12} className={"pt-0"}>
              {fieldDocs.length < 6 && (
                <MultipleUpload
                  nostyle={true}
                  text={l(`Add More`, lang)}
                  isLocation={true}
                  setFile={(file, location) => {
                    setFieldDocs([...fieldDocs, { file, location }]);
                  }}
                  // onSelect={() => {
                  //   setFieldErrors((p) => ({ ...p, selfie: "" }));
                  // }}
                />
              )}
            </Grid>

            {/* ////// References ///////////// */}

            <Grid item xs={12} sm={12}>
              <label
                className="form-label mb-0"
                style={{
                  fontSize: 15,
                  fontWeight: 600,
                  color: "black !important",
                }}
              >
                {l("Reference 1", lang)}
              </label>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label" style={{ fontSize: 13 }}>
                {l("Name", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.reference1_name}
                type="text"
                className="form-input w-100 input-blue white-bg"
                placeholder={l("Name", lang)}
                autoComplete="family-name"
                value={journeyData.reference1_name}
                onChange={(e) => {
                  if (
                    e.target.value !== "" &&
                    !/^[a-zA-Z ]+$/.test(e.target.value)
                  ) {
                    return false;
                  }
                  setError({ ...errors, reference1_name: "" });
                  setJourneyData({
                    ...journeyData,
                    reference1_name: e.target.value,
                  });
                }}
              />
              <span className="error">{errors.reference1_name}</span>
            </Grid>
            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label" style={{ fontSize: 13 }}>
                {l("Mobile Number", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.reference1_mob}
                type="text"
                className="form-input w-100 input-blue white-bg"
                placeholder={l("Mobile Number", lang)}
                autoComplete="family-name"
                value={journeyData.reference1_mob}
                inputMode="numeric"
                maxLength={10}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 10) return false;
                  setError({ ...errors, reference1_mob: "" });
                  setJourneyData({
                    ...journeyData,
                    reference1_mob: e.target.value,
                  });
                }}
              />
              <span className="error">{errors.reference1_mob}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label" style={{ fontSize: 13 }}>
                {l("Relation with borrower", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              {/* <input
                ref={refs?.reference1_relation}
                type="text"
                className="form-input w-100 input-blue"
                placeholder="Enter Relationship"
                autoComplete="family-name"
                value={journeyData.reference1_relation}
                onChange={(e) => {
                  if (
                    e.target.value !== "" &&
                    !/^[a-zA-Z ]+$/.test(e.target.value)
                  ) {
                    return false;
                  }
                  setError({ ...errors, reference1_relation: "" });
                  setJourneyData({
                    ...journeyData,
                    reference1_relation: e.target.value,
                  });
                }}
              /> */}

              <CustomInputAutocomplete
                options={["Neighbour", "Relative", "Friend", "Other"]}
                className="white-bg"
                value={journeyData?.reference1_relation}
                text={l("Select an option", lang)}
                onChange={(val) => {
                  setError({ ...errors, reference1_relation: "" });
                  setJourneyData({
                    ...journeyData,
                    reference1_relation: val,
                  });
                }}
              />

              <span className="error">{errors.reference1_relation}</span>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mt-2">
            <Grid item xs={12} sm={12}>
              <label
                className="form-label mb-0"
                style={{
                  fontSize: 15,
                  fontWeight: 600,
                  color: "black !important",
                }}
              >
                {l("Reference 2", lang)}
              </label>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label" style={{ fontSize: 13 }}>
                {l("Name", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.reference2_name}
                type="text"
                className="form-input w-100 input-blue white-bg"
                placeholder={l("Name", lang)}
                autoComplete="family-name"
                value={journeyData.reference2_name}
                onChange={(e) => {
                  if (
                    e.target.value !== "" &&
                    !/^[a-zA-Z ]+$/.test(e.target.value)
                  ) {
                    return false;
                  }
                  setError({ ...errors, reference2_name: "" });
                  setJourneyData({
                    ...journeyData,
                    reference2_name: e.target.value,
                  });
                }}
              />
              <span className="error">{errors.reference2_name}</span>
            </Grid>
            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label" style={{ fontSize: 13 }}>
                {l("Mobile Number", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.reference2_mob}
                type="text"
                className="form-input w-100 input-blue white-bg"
                placeholder={l("Mobile Number", lang)}
                autoComplete="family-name"
                value={journeyData.reference2_mob}
                maxLength={10}
                inputMode="numeric"
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 10) return false;
                  setError({ ...errors, reference2_mob: "" });
                  setJourneyData({
                    ...journeyData,
                    reference2_mob: e.target.value,
                  });
                }}
              />
              <span className="error">{errors.reference2_mob}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label" style={{ fontSize: 13 }}>
                {l("Relation with borrower", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              {/* <input
                ref={refs?.reference2_relation}
                type="text"
                className="form-input w-100 input-blue mb-5"
                placeholder="Enter Relationship"
                autoComplete="family-name"
                value={journeyData.reference2_relation}
                onChange={(e) => {
                  if (
                    e.target.value !== "" &&
                    !/^[a-zA-Z ]+$/.test(e.target.value)
                  ) {
                    return false;
                  }
                  setError({ ...errors, reference2_relation: "" });
                  setJourneyData({
                    ...journeyData,
                    reference2_relation: e.target.value,
                  });
                }}
              /> */}

              <CustomInputAutocomplete
                options={["Neighbour", "Relative", "Friend", "Other"]}
                className="white-bg"
                value={journeyData?.reference2_relation}
                text={l("Select an option", lang)}
                onChange={(val) => {
                  setError({ ...errors, reference2_relation: "" });
                  setJourneyData({
                    ...journeyData,
                    reference2_relation: val,
                  });
                }}
              />

              <span className="error">{errors.reference2_relation}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Accessbility till premise", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                // ref={refs.is_property_owned}
                ref={refs?.premise_accessibility}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={journeyData?.premise_accessibility}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    premise_accessibility: e.target?.value,
                  });
                  setError({ ...errors, premise_accessibility: "" });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"easy"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Easy To reach", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"difficult"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Difficult", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{errors.premise_accessibility}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label ref={refs?.fi_remarks} className="form-label">
                {l("Final Remark on the file", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <CustomInputAutocomplete
                options={["Pass", "Fail"]}
                className="white-bg"
                value={journeyData?.fi_remark}
                text={l("Select an option", lang)}
                onChange={(val) => {
                  setError({ ...errors, fi_remark: "" });
                  setJourneyData({ ...journeyData, fi_remark: val });
                }}
              />

              <span className="error">{errors.fi_remarks}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label">
                {l("Agent Name", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.investigator_name}
                type="text"
                className="form-input w-100 px-3 white-bg"
                required
                placeholder={l("Agent Name", lang)}
                style={{ fontSize: "14px" }}
                name="name"
                autoComplete="family-name"
                value={journeyData?.investigator_name}
                onChange={(e) => {
                  setError({ ...errors, investigator_name: "" });
                  setJourneyData((st) => ({
                    ...st,
                    investigator_name: e.target.value,
                  }));
                }}
              />
              <span className="error">{errors.investigator_name}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={labelHolder}>
              <label className="form-label">
                {l("Agent phone Number", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.investigator_mobile}
                type="text"
                className="form-input w-100 px-3 white-bg"
                required
                placeholder={l("Agent phone Number", lang)}
                style={{ fontSize: "14px" }}
                name="phone"
                autoComplete="family-name"
                value={journeyData?.investigator_mobile}
                maxLength={10}
                inputMode="numeric"
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 10) return false;
                  setError({ ...errors, investigator_mobile: "" });
                  setJourneyData((st) => ({
                    ...st,
                    investigator_mobile: e.target.value,
                  }));
                }}
              />
              <span className="error">{errors.investigator_mobile}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                className="mb-2"
                control={<Checkbox color="success" />}
                onChange={(e) => setAccepted(e.target.checked)}
                defaultChecked={isAccepted}
                label={
                  <span
                    className="checkbox-txt"
                    style={{ fontSize: 10, lineHeight: "5px" }}
                  >
                    {l(
                      "I hereby declare that the details furnished above are true and correct to the best of my knowledge",
                      lang
                    )}
                  </span>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                // className={`btn-submit ${
                //   !showFinalCalculation && "btn-submit-disabled"
                // }`}
                className={`btn-submit`}
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Proceed", lang)
                )}
              </Button>
            </Grid>
          </Grid>
        </div>

        {/* <Paper className="paper-bottom" elevation={3} ref={bottomNav}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                // className={`btn-submit ${
                //   !showFinalCalculation && "btn-submit-disabled"
                // }`}
                className={`btn-submit`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper> */}
      </Box>
      <OtpScreenOver
        open={otpData?.open}
        handleClose={() => setOtpData({ ...otpData, open: false })}
        onComplete={(status) => {
          if (status) {
            handleSubmitFi();
          }
        }}
      />
      <RenderCanvas />
    </>
  );
});

export default FieldInvestigationV2;
