import axios from "axios";
import { baseURL, environment } from "../config";

const API = axios.create({
  baseURL: baseURL[environment],
  withCredentials: true,
});


API.interceptors.request.use(
  config => {
      const nbfc = localStorage.getItem('nbfc');
      // config.headers['nbfc-id'] = nbfc;
      config.headers['application'] = "AGENT-ERICK-V2";
      return config;
  },
  error => {
      Promise.reject(error)
});


export const agentLogin = (data) => API.post(`/erick/v2/agent-login`, data);
export const verifyAuth = (token) => API.get(`/erick/v2/verify-auth?fcm_token=${token}`);

export const mobileLogin = (data) => API.post(`/erick/v2/dealer-login`, data);
export const verifyOtp2 = (payload) => API.post(`/erick/v2/verify-login`,payload);

export const getAnchors = () => API.get(`erick/v2/anchors`);
export const fetchApplicationData = (application_id) => API.get(`erick/v2/loan-application/${application_id}`);
export const createCustomer = (payload) => API.post(`/erick/v2/create-customer`,payload);
export const updateAddress = (payload, id) => API.put(`/erick/v2/customer-data/${id}`,payload);
export const verifyOtp = (payload) => API.post(`/erick/v2/verify-otp`,payload);
export const sentOtp = (payload) => API.post(`/erick/v2/resent-otp`,payload);

export const createCoCustomer = (payload) => API.post(`/erick/v2/create-cocustomer`,payload);
export const updateCoCustomer = (payload, id) => API.put(`/erick/v2/co-customer/${id}`,payload);
export const phychometricQuestions = (payload, id) => API.put(`erick/v2/phychometric-questions/${id}`, payload);

export const updateLoanData = (payload, id) => API.put(`/erick/v2/loan-details/${id}`,payload);
export const customerFieldInvestigation = (payload) => API.put(`/erick/v2/customer-field-investigation`,payload);
export const uploadPostDisbursement = (payload) => API.put(`/erick/v2/post-disbursement`,payload);
export const preDisbursementCustomer = (payload,id) => API.post(`/erick/v2/pre-disbursement/${id}?type=customer`,payload);
export const preDisbursementCoCustomer = (payload,id) => API.post(`/erick/vV11/pre-disbursement/${id}?type=co-customer`,payload);
export const preDisbursementBankDocs = (payload,id) => API.post(`/erick/v2/pre-disbursement/${id}?type=bank`,payload);
export const preDisbursementDocument = (payload,id,part) => API.post(`/erick/v2/pre-disbursement/${id}?type=document&part=${part}`,payload);
export const addNewCoapplicant = (id) => API.put(`/erick/v2/change-rejected-cocustomer/${id}`);
export const chequeOcr = (payload) => API.post(`/common/cheque-ocr`,payload);
export const createMandate = (id) => API.post(`/common/create-mandate/${id}`);
export const updateEnachStatus = (id, status) => API.put(`/common/mandate-data/${id}`, {status});

export const initiateUpiAuto = (payload) => API.post(`/common/upi-autopay`, payload);
export const proceedPayment = (id) => API.post(`/erick/v2/proceed-to-disbursement/${id}`);
// V2

export const coCustomerAdd = (id) => API.post(`/erick/v2/co-customer-add/${id}`);
export const fiSendOtp = (mobile,id, data) => API.post(`/erick/v2/send-otp/${id}?mobile_number=${mobile}`,data);
export const verifyPincode = (pin)=> API.get(`common/pincode/${pin}`)
export const updateFieldInvestigation = (payload) => API.put(`/erick/v2/customer-field-investigation`,payload);


export const getDraftApplication = () => API.get(`/erick/v2/draft`);
export const fetchAllApplications = (filter,page,limit) => API.get(`/common/all-applications?${ filter !== 'all'  ? "type=filter&filter="+filter+'&' : ''}limit=${limit}&page=${page}`);
export const searchApplication = (query,limit) => API.get(`/common/all-applications?type=search&param=${query}&limit=${limit}`);

export const getSpecificDoc = (applicationId,name,collection) => API.get(`/erick/v2/get-specific-docs/${applicationId}?document_name=${name}&collection=${collection}`);
export const uploadDoc = (payload) => API.post(`/erick/v2/single-page-upload`, payload);
export const deleteDoc = (payload) => API.delete(`/common/delete-doc`, { data: payload });
export const submitCombineDoc = (payload) => API.put(`/erick/v2/doc-page-completed`, payload);

export const incentiveData = (allRequired,totalCoin,id) => API.get(`/incentives/incentive-detials?allAgentIncentivesRequired=${allRequired}&agentTotalCoinRequired=${totalCoin}&applicationId=${id}`);

export const logout = (payload) => API.post(`/erick/v2/logout`, payload);

// V3

export const updateBankDetails = (payload,id) => API.post(`/erick/v2/bank-and-cheque-details/${id}`,payload);
export const reUpdateBankDetails = (payload) => API.post(`/common/penny-drop-and-update-bank`,payload);
export const acceptLoanOffer = (id) => API.put(`/erick/v2/accept-loan-offer/${id}`);
export const uploadDocument = (payload,id, params="") => API.post(`/erick/v2/document-upload/${id}${params}`,payload);
export const documentStatus = (id) => API.get(`/erick/v2/document-status/${id}`);
export const physicalMandate = (id) => API.post(`/common/create-mandate/${id}?mandate_type=physical`);
export const adhaarMandate = (id) => API.post(`/common/create-mandate/${id}?mandate_type=esign`);
export const uploadMandate = (payload) => API.post(`/common/uploadNach`, payload);
export const fetchQr = (payload) => API.post(`/common/downloadqr`, payload);
export const deleteCoCustomer = (id) => API.delete(`/erick/v2/co-customer/${id}`);
export const fetchDashboardData = () => API.get(`/erick/v2/dashboard-data`);
export const updateLanguage = (payload) => API.put(`/erick/v2/update-language`, payload);
export const clearRejectList = (id) => API.put(`/erick/v2/clear-rejection-list/${id}`);
export const switchCookie = (data) => API.put(`/erick/v2/switch-application`,data);
export const getEncryptedToken = () => API.get(`/erick/v2/get-token`);

export const getAllApplicationsV2 = (query) => API.get(`erick/v2/get-all-applications?${query}`);
export const getNotification = (query) => API.get(`erick/v2/get-all-notifications?${query}`);
export const markReadNotification = () => API.put(`erick/v2/mark-read-notification`);
export const esignLoanAgreement = (payload) => API.post(`common/esign-generate`, payload);
export const updateEsignStatusApi = (payload) => API.post(`common/esign-webhook`, payload);
export const getEsignDocumentBase = (payload) => API.post(`common/manual-esignPdf-download`, payload);
export const fetchSchedule = (id) => API.get(`lms/loan/schedule-v2/${id}`);
export const fetchNachableBankList = () => API.get(`common/get-nachable-bank-list`);


// Demo
export const demoDashboardData=()=>API.get('erick/v2/demo-dashboard-data');
export const getCollectionsData = (query) => API.get(`/collection`);
export const getAllApplicationWithFilter = (filter) => API.get(`/erick/v2/all-applications?filter=${filter}`);