import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { UserContext } from "../Contexts/UserContext";
import { isNameValid, isPincodeValid } from "../validations/fieldValidations";
import { l } from '../util/languageConvertor';
import { GlobalContext } from "../Contexts/GlobalContext";

const RegisterName = () => {
  const { completeSignUp, error } = useAuth();

  const { user } = useContext(UserContext);
  const { langState } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [lang, setLang] = langState;

  const [name, setName] = useState("");
  const [pincode, setPincode] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [pinResponse, setPinResponse] = useState({
    status: false,
    state: "",
    msg: "",
    loading: false,
  });

  useEffect(() => {
    if (user?.name) navigate("/home");
  }, [user]);

  const isValid = async () => {
    let valid = true;

    let nameRes = isNameValid(name);
    if (nameRes !== "") {
      setNameError(nameRes);
      valid = false;
    }

    if (!pinResponse.status) {
      valid = false;
      if (pinResponse.msg === "") {
        await isPincodeValid(pincode, setPinResponse);
      }
    }

    return valid;
  };

  const handleSubmit = () => {
    if (isValid()) {
      completeSignUp(name, pincode, setLoading);
    }
    return false;
  };

  return (
    <Box sx={{ pb: 7, pt: 3 }}>
      <div className="content-center">
        <CssBaseline />
        <div className="response-wrapper p-3">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Full Name", lang)} <span className="mand-star">*</span>
              </label>
              <input
                className="form-input w-100"
                autoComplete="given-name"
                name="businessName"
                required
                fullWidth
                placeholder="Enter your Full Name"
                value={name}
                onChange={(e) => {
                  setNameError("");
                  setName(e.target.value);
                }}
                onBlur={() => {
                  setNameError(isNameValid(name));
                }}
              />
              <span className="error">{nameError}</span>
            </Grid>

            <Grid item xs={6} sm={6} style={{ marginBottom: "80px" }}>
              <label className="form-label">
                Business Pincode <span className="mand-star">*</span>
              </label>
              <input
                type="number"
                className="form-input w-100"
                required
                fullWidth
                placeholder="Pincode"
                name="pincode"
                autoComplete="family-name"
                value={pincode}
                onKeyDown={(e) => {
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                }}
                onChange={(e) => {
                  if (e.target.value.length > 6) return false;
                  setPincode(e.target.value);
                }}
                onKeyUp={(e) => {
                  isPincodeValid(e.target.value, setPinResponse);
                }}
              />

              {pinResponse.loading && (
                <>
                  <CircularProgress size={20} className="mt-1 me-2" />{" "}
                  <span className="verify-txt">Validating Pincode</span>
                </>
              )}
              {!pinResponse.loading && !pinResponse.status && (
                <span className="error">{pinResponse.msg}</span>
              )}
            </Grid>

            {/* {!pinResponse.loading && pinResponse.status && (
            <Grid item xs={6} sm={6} className="d-flex">
              <div className="align-self-end d-flex">
                <p style={{ fontSize: "10px", color: "gray" }}>
                  <LocationOnIcon />
                  {pinResponse.state}
                </p>
              </div>
            </Grid>
          )} */}
          </Grid>
        </div>

        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, boxShadow: 0 }}
          elevation={3}
        >
          <BottomNavigation style={{ height: "auto" }} showLabels>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                onClick={handleSubmit}
              >
                submit
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </div>
    </Box>
  );
};

export default RegisterName;
