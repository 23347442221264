import React, { forwardRef, useEffect, useContext } from "react";
import {
  createMandate,
  initiateUpiAuto,
  updateEnachStatus,
} from "../../../../api/agent";
import loadingGif from "../../../../assets/loading.gif";
import digioPic from "../../../../assets/digio.png";
import "../../../../util/digio";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useState } from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import VerifiedIcon from "@mui/icons-material/Verified";
import { environment } from "../../../../config";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { l } from "../../../../util/languageConvertor";


const UpiAutoCollect = forwardRef(({}, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [upiStatus, setUpiStatus] = useState("pending");
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const { application_id } = useParams();
  const navigate = useNavigate();

  const initiate = async () => {
    try {
      let response = await initiateUpiAuto({ applicationId: application_id });
      //
      const { data } = response;
      if (data?.status) {
        setUpiStatus("successfull");
        setJourneyData({
          ...journeyData,
          upi_link_sent: true,
        });
      } else {
        setUpiStatus("unsuccessfull");
        enqueueSnackbar(l("UPI Auto collect setup failed", lang), {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setUpiStatus("unsuccessfull");
      setJourneyData({
        ...journeyData,
        upi_status: null,
      });
      enqueueSnackbar(l("UPI Auto collect setup failed", lang), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    initiate();
  }, [journeyData?.application_id]);

  const { getApplicationDetails, getRealStatus } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  return (
    <>
      {upiStatus === "pending" && (
        <div className="mt-5">
          <p className="text-center" style={{ marginTop: "200px" }}>
            <img src={loadingGif} alt="" width={50} />
          </p>
          <p
            className="text-center"
            style={{ color: "#573A7A", fontSize: "30px" }}
          >
            Setting Up UPI Auto collect
          </p>
          {/* <p className="text-center">
            Powered by <img src={digioPic} width="50"></img>
          </p> */}
        </div>
      )}

      {upiStatus === "unsuccessfull" && (
        <div className="mt-5">
          <p className="text-center" style={{ marginTop: "120px" }}>
            {/* <img src={loadingGif} alt="" width={50} /> */}
            <DoDisturbIcon style={{ fontSize: "120px", color: "red" }} />
          </p>
          <p
            className="text-center"
            style={{ color: "#573A7A", fontSize: "30px" }}
          >
            Failed
          </p>

          <div className="my-3">
            <Button
              onClick={() => {
                setJourneyData({ ...journeyData, upi_status: null });
                initiate();
              }}
            >
              Try Again
            </Button>
            <Button
              onClick={() => {
                navigate(`/create-loan/documents-upload/${application_id}/1`);
              }}
            >
              Go back
            </Button>
          </div>
          {/* <p className="text-center">
            Powered by <img src={digioPic} width="50"></img>
          </p> */}
        </div>
      )}

      {upiStatus === "successfull" && (
        <div className="mt-5">
          <p className="text-center" style={{ marginTop: "120px" }}>
            {/* <img src={loadingGif} alt="" width={50} /> */}
            <VerifiedIcon style={{ fontSize: "120px", color: "green" }} />
          </p>
          <p
            className="text-center"
            style={{ color: "#573A7A", fontSize: "30px" }}
          >
            Payment Link sent Successfully!
          </p>

          <div className="my-3 content-center">
            <Button
              onClick={() => {
                navigate(`/create-loan/documents-upload/${application_id}/1`);
              }}
            >
              Go back
            </Button>
          </div>
          {/* <p className="text-center">
            Powered by <img src={digioPic} width="50"></img>
          </p> */}
        </div>
      )}
    </>
  );
});

export default UpiAutoCollect;
